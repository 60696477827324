// @flow

import React from 'react';
import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import QuizCreatePopup from './QuizCreatePopup';
import QuizListPopupContainer from '../../containers/QuizListPopupContainer.jsx';

const QuizManagerPopupWrapper = styled.div`
    position: absolute;
    bottom: 100%;
    right: 0px;
    margin-bottom: 20px;
    width: max-content;
    background-color: ${({ theme }) => theme.color.secondBackground};
    border-radius: 10px;
    overflow: hidden;
`;

export type QuizManagementPopupProps = {
    execCommand: (cmd: string, params: string) => void,
    closePopup: () => void,
    isOpen: boolean
};

const QuizManagementPopup = ({
    execCommand,
    closePopup,
    isOpen
}: QuizManagementPopupProps): React$Element<any> => {
    const ref = useRef();
    const [isListOpen, setIsListOpen] = useState(true);
    const [quizToEdit, setQuizToEdit] = useState(null);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            const node = ref.current;
            if (isOpen && node && !node.contains(e.target)) {
                closePopup();
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen, closePopup]);

    const changePopup = useCallback((quizEdit) => {
        setQuizToEdit(quizEdit);
        setIsListOpen((state) => !state);
    }, []);

    return (
        <QuizManagerPopupWrapper ref={ref}>
            {isListOpen ? (
                <QuizListPopupContainer
                    execCommand={execCommand}
                    closePopup={closePopup}
                    changePopup={changePopup}
                />
            ) : (
                <QuizCreatePopup
                    execCommand={execCommand}
                    closePopup={closePopup}
                    changePopup={() => changePopup(null)}
                    quizToEdit={quizToEdit}
                />
            )}
        </QuizManagerPopupWrapper>
    );
};

export default QuizManagementPopup;
