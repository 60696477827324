// @flow

// Modules
import WsPush from '@winamax/wspush-client';

// Methods
import Token from './token';

/**
 * Constants / Variables
 */

export const METHODS = Object.freeze({
    JOIN_ROOM: 'JoinRoom',
    SEND_MESSAGE: 'SendMessage',
    ANSWER: 'Answer',
    BAN_USER: 'BanUser',
    UNBAN_USER: 'UnbanUser',
    SEND_MAIN_MESSAGE: 'SendMainMessage',
    REMOVE_MAIN_MESSAGE: 'RemoveMainMessage',
    REMOVE_MESSAGE: 'RemoveMessage',
    ADD_BAD_WORD: 'AddBadWordByModerator',
    DELETE_BAD_WORD: 'DeleteBadWordByModerator',
    CREATE_QUIZ: 'CreateQuiz',
    GET_QUIZ_LIST: 'GetQuizList',
    ACTIVATE_QUIZ: 'ActivateQuiz',
    DRAW_QUIZ_WINNERS: 'DrawQuizWinners',
    DELETE_QUIZ: 'DeleteQuiz',
    SET_SLOW_MODE: 'SetSlowMode',
    EDIT_QUIZ: 'EditQuiz',
    OPEN_ROOM: 'OpenRoomByModo',
    CLOSE_ROOM: 'CloseRoomByModo'
});

type MethodsType = $Values<typeof METHODS>;

export const NameSpace = Object.freeze({
    CHAT: 'core/chatamax',
    QUIZ: 'core/chat/quiz'
});

type NSType = $Values<typeof NameSpace>;

// Ids of specific messages
let ids = {
    JoinRoom: null,
    SendMessage: null,
    Answer: null,
    BanUser: null,
    UnbanUser: null,
    SendMainMessage: null,
    RemoveMainMessage: null,
    RemoveMessage: null,
    AddBadWordByModerator: null,
    DeleteBadWordByModerator: null,
    CreateQuiz: null,
    GetQuizList: null,
    ActivateQuiz: null,
    DrawQuizWinners: null,
    DeleteQuiz: null,
    SetSlowMode: null,
    EditQuiz: null,
    OpenRoomByModo: null,
    CloseRoomByModo: null
};

// Is socket connected ?
let connected = false;

// Is socket authenticated ?
let authenticated = false;

// Last token sended
let lastJwtToken = null;

/**
 * Methods
 */

/**
 * Component
 */
let WsPushClient: Object = {};

WsPushClient.socketConnection = (env, operator, callback) => {
    WsPush.initialize(env, operator, callback);
    WsPush.connect();
};

WsPushClient.getInfos = () => {
    return {
        ids,
        connected,
        authenticated
    };
};

WsPushClient.setConnected = (value: boolean) => {
    connected = value;
};

WsPushClient.setAuthenticated = (value: boolean) => {
    authenticated = value;
};

WsPushClient.callAPI = (method: MethodsType, params: Object, ns: NSType) => {
    ids[method] = WsPush.call(method, params, ns);
};

WsPushClient.subscribe = (isPrivate: boolean, channelId: string) => {
    WsPush.subscribe(isPrivate, channelId);
};

WsPushClient.unsubscribe = (isPrivate: boolean, channelId: string) => {
    WsPush.unsubscribe(isPrivate, channelId);
};

WsPushClient.generateToken = async () => {
    const getToken = Token.getTokenFunc();
    if (getToken) {
        const data = await getToken();
        return data.token;
    } else if (__BUILD_TARGET__ === 'web') {
        return Token.getWebToken(WsPushClient.setAuthenticate);
    } else {
        return '';
    }
};

WsPushClient.setAuthenticate = (jwtToken) => {
    if (jwtToken && jwtToken !== lastJwtToken) {
        lastJwtToken = jwtToken;

        WsPush.authenticate(jwtToken);
    }
};

WsPushClient.setLastJwt = (jwtToken) => {
    lastJwtToken = jwtToken;
};

WsPushClient.disconnect = () => {
    WsPush.disconnect();

    // Reset connection infos
    connected = false;
    authenticated = false;
    lastJwtToken = null;
};

export default WsPushClient;
