// @flow

/*
 ** Imports
 */

// Modules
import * as React from 'react';
import { each, extend, filter } from 'lodash';

// Foundation
import Jed from './ext-lib/jed';
import Formatter from './formatter';

// Locales
import en_US from '../assets/translation/en_US';
import fr_FR from '../assets/translation/fr_FR';
import es_ES from '../assets/translation/es_ES';

const LocalesLbl = Object.freeze({
    es: 'es_ES',
    fr: 'fr_FR',
    de: 'de_DE',
    it: 'it_IT',
    pt: 'pt_PT',
    en: 'en_US'
});
let jed: any = null;
let selectedLang: ?string = null;
let locales: Object = { en_US, fr_FR, es_ES };

/*
 ** Functions
 */

// Data est un object Key/label qui alimente le catalogue des labels de l'application pour une langue donnée
function getJedConfig(language: string, plural?: string, data?: any) {
    const result = {};
    if (!data) {
        data = getLangCatalog(language).translations;
    }

    let defaultPlural = 'nplurals=2; plural=(n > 1);';
    if (language === 'es_ES' || language === 'en_US') {
        defaultPlural = 'nplurals=2; plural=(n != 1);';
    }

    // On ajoute à chaque catalogue, le block de configuration
    each(data, (v, catalog) => {
        result[catalog] = extend(v, {
            '': {
                domain: catalog,
                lang: language,
                plural_forms: plural || defaultPlural
            }
        });
    });
    return result;
}

/*
 * Retourne le catalogue des traduction pour une langue donnée.
 */
function getLangCatalog(lang: string): any {
    switch (lang) {
        case 'en_US':
            return locales.en_US;
        case 'fr_FR':
            return locales.fr_FR;
        case 'es_ES':
            return locales.es_ES;
    }
    return null;
}

const initializeL10n = (
    language: string,
    plural?: string,
    next: any,
    action: any
): void => {
    jed = new Jed({
        locale_data: getJedConfig(language, plural)
    });
};

export const initLocales = (allLocales: Object): void => {
    locales = allLocales;
};

const getNavLang = (lang: string) => {
    const langsList = {
        es: ['es_ES', 'es', 'es-ES'],
        fr: ['fr_FR', 'fr', 'fr-FR'],
        de: ['de_DE', 'de', 'de-DE'],
        it: ['it_IT', 'it', 'it-IT'],
        pt: ['pt_PT', 'pt', 'pt-PT'],
        en: ['en_US', 'en', 'en-US', 'en_GB', 'en-GB']
    };
    for (const language of Object.keys(langsList)) {
        if (langsList[language].indexOf(lang) !== -1) {
            return LocalesLbl[language];
        }
    }
    return LocalesLbl.fr;
};

export const changeLanguageL10n = (language: ?string, plural?: string): void => {
    if (!language) {
        return;
    }
    const lang = getNavLang(language);
    if (selectedLang !== lang) {
        selectedLang = lang;
        if (!jed) {
            initializeL10n(lang, plural);
        } else {
            jed.options.locale_data = getJedConfig(lang, plural);
        }
        setFormatterData(lang);
    }
};

export const __ = (
    str: ?string,
    args: ?any,
    plural: ?string,
    catalog: ?string
): string => {
    let trad = null;

    if (!str || !jed) {
        return '';
    }

    trad = jed.translate(str);
    if (catalog) {
        trad = trad.onDomain(catalog);
    }

    if (!args && args !== 0) {
        args = [];
    }

    if (!(args instanceof Array)) {
        args = [args];
    }

    if (plural && args.length) {
        trad = trad.ifPlural(args[0], plural);
    }
    const traduction = trad.fetch.apply(trad, args);
    return traduction;
};

export const __jsx = (
    str: ?string,
    args: ?any,
    plural: ?string,
    catalog: ?string
): ?React.Node => {
    let trad = null;

    if (!str || !jed) {
        return '';
    }

    trad = jed.translate(str);

    if (catalog) {
        trad = trad.onDomain(catalog);
    }

    if (!args && args !== 0) {
        args = [];
    }

    if (!(args instanceof Array)) {
        args = [args];
    }

    !(plural && args.length) &&
        console.error("You can't translate plural form of jsx");

    let jsx = trad.fetch().split(/(%[1-9]\$s)/g);

    each(args, (arg, index) => {
        const elem = jsx.indexOf(`%${index + 1}$s`);

        if (elem !== -1) {
            jsx[elem] = arg;
        }
    });
    return filter(jsx);
};

export const getFormatter = (): any => {
    return Formatter;
};

export const setFormatterData = (lang: string): any => {
    Formatter.setLanguage(lang);
};
