import { getNicknameColor } from '../utils/formatter';
import colors from './palette';

const DefaultTheme = {
    color: {
        mainBackground: colors.chineseblack,
        secondBackground: colors.darkjungleblack,
        thirdLayerBg: colors.gunmetalblack,
        firstButton: colors.mikadoyellow,
        secondButton: colors.blondyellow,
        formButton: colors.white,
        mainText: colors.white,
        nicknamesTxt: {},
        modoNicknameTxt: colors.winamaxRed,
        secondText: colors.black,
        quizSecondBg: colors.darkgunmetalblack,
        inputBackground: colors.darkouterspaceGrey,
        quizTitle: colors.oceangreen,
        formInputBorder: colors.outerspaceGrey,
        alertErrorText: colors.errorRed,
        alertErrorLGrad: colors.persianRed,
        alertErrorDGrad: colors.darkSienna,
        alertInfoText: colors.infoBlue,
        alertInfoLGrad: colors.indigo,
        alertInfoDGrad: colors.cetaceanBlue,
        errorColor: colors.errorRed,
        connectedDot: colors.slimygreen,
        discoDot: colors.electicGrey,
        scrollBar: colors.mikadoyellow
    },
    font: {
        message: {
            size: '14px',
            nicknameWeight: 'bold',
            modoNicknameWeight: 'bolder'
        },
        general: { family: 'chatamax-FiraSans', weight: '500' },
        mainMessage: { size: '20px', weight: 'bold' },
        memberNickname: { size: '10px', family: 'inherit', weight: 'bold' },
        formInput: { size: '16px' },
        formLabel: { size: '18px', weight: 'bold' },
        popupHeader: { size: '20px', weight: 'bold' },
        quizQuestion: { size: '20px', weight: 'bold' },
        quizAnswer: { size: '14px' },
        quizListQuestion: { size: '16px', weight: 'bold' },
        generalButton: { size: '15px', weight: 'bold' },
        messageInput: { size: '14px' },
        msgTime: { size: '10px', weight: '500' },
        msgSeparator: { size: '14px', weight: '500' }
    },
    size: {
        memberDotRadius: '6px'
    }
};

const WinamaxtvTheme = {
    ...DefaultTheme,
    color: {
        ...DefaultTheme.color
    },
    font: {
        ...DefaultTheme.font
    }
};

const King5Theme = {
    ...DefaultTheme,
    color: {
        ...DefaultTheme.color,
        mainBackground: colors.eerieBlack,
        secondBackground: colors.onyx,
        inputBackground: colors.onyx
    },
    font: {
        ...DefaultTheme.font,
        message: {
            size: '16px',
            nicknameWeight: 'bold',
            modoNicknameWeight: 'bolder'
        },
        general: { family: 'chatamax-Yantramanav', weight: 'bold' },
        mainMessage: { size: '18px', weight: 'inherit' },
        memberNickname: {
            size: '14px',
            family: 'chatamax-GermaniaOne',
            weight: 'normal'
        }
    }
};

const GngTheme = {
    ...DefaultTheme,
    color: {
        ...DefaultTheme.color
    },
    font: {
        ...DefaultTheme.font,
        memberNickname: { size: '13px', family: 'inherit', weight: 'bold' }
    },
    size: {
        ...DefaultTheme.size,
        memberDotRadius: '10px'
    }
};

const themeGenerator = {
    default: DefaultTheme,
    winamaxtv: WinamaxtvTheme,
    king5: King5Theme,
    gng: GngTheme
};

export const addUserNicknameColor = (nickname) => {
    if (!themeGenerator['default'].color.nicknamesTxt[nickname]) {
        themeGenerator['default'].color.nicknamesTxt[nickname] =
            getNicknameColor(nickname);
    }
};

export default themeGenerator;
