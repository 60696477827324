// @flow

import React from 'react';
import styled from 'styled-components';
import {
    PopupHeaderWrapper,
    PopupHeaderText,
    StyledButton
} from './sharedStyledComponent';

const ValidationPopupWrapper = styled.div`
    width: max-content;
    background-color: ${({ theme }) => theme.color.secondBackground};
    border-radius: 10px;
    overflow: hidden;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 10px;
    flex-direction: row;
    align-items: center;
`;

const Button = styled(StyledButton)`
    color: ${({ theme }) => theme.color.secondText};
    background-color: ${({ theme }) => theme.color.formButton};
    width: 150px;
`;

export type ValidationPopupProps = {
    action: () => void,
    closePopup: () => void,
    question: string,
    yesLabel: string,
    noLabel: string
};

const ValidationPopup = ({
    action,
    closePopup,
    question,
    yesLabel,
    noLabel
}: ValidationPopupProps): React$Element<any> => {
    const handleYes = (e) => {
        action();
        closePopup();
    };

    return (
        <ValidationPopupWrapper>
            <PopupHeaderWrapper>
                <PopupHeaderText>{question}</PopupHeaderText>
            </PopupHeaderWrapper>
            <div>
                <ButtonWrapper>
                    <Button key={'YesBtnValidationKey'} onClick={handleYes}>
                        {yesLabel}
                    </Button>
                    <Button key={'NoBtnValidationKey'} onClick={closePopup}>
                        {noLabel}
                    </Button>
                </ButtonWrapper>
            </div>
        </ValidationPopupWrapper>
    );
};

export default ValidationPopup;
