// @flow

import React from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { StyledButton, VerticalSpacer } from './common/sharedStyledComponent';
import useDidMountEffect from '../hooks/useDidMountEffect';
import InputTextAreaContainer from '../containers/InputTextAreaContainer.jsx';
import type { InputAlert } from '../types/message';
import QuizManagementPopup from './input_bar_components/QuizManagementPopup.jsx';
import { AlertTypes, createInputAlert } from '../types/message';
import { getSlowModeMessage } from '../utils/infos';
import ToggleSwitch from './common/ToggleSwitch';
import type { FeaturesToggle, RoomStatusType } from '../types/room';
import { RoomStatus } from '../types/room';
import ValidationPopup from './common/ValidationPopup';
import ButtonCmdAction from './common/ButtonCmdAction.jsx';
import { ArrowDown as ScrollDownIcon } from 'assets/images';
import { ScrollButtonContext } from './ChatRoot.jsx';
import { __ } from '../utils/translator';
import AlertMessage from './common/AlertMessage';

const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;

const ScrollDownButton = styled(ButtonCmdAction)`
    height: 20px;
    position: absolute;
    top: -45px;
    right: 10px;
`;

const ButtonWrapper = styled.div`
    position: relative;
    margin-left: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
`;

const StickyCmdButton = styled(StyledButton)`
    width: 170px;
    background-color: ${({ theme }) => theme.color.firstButton};
`;

const QuizPopupButton = styled(StyledButton)`
    width: 170px;
    background-color: ${({ theme }) => theme.color.secondButton};
`;

const CloseRoomPopupWrapper = styled.div`
    position: absolute;
    bottom: 100%;
    right: 0px;
    margin-bottom: 20px;
    width: max-content;
`;

const AlertMessageWrapper = styled.div`
    position: absolute;
    z-index: 1;
    transform-origin: bottom left;
    transform: translate(0, -100%);
    width: 100%;
    padding-bottom: 3px;
    display: flex;
    justify-content: center;
`;

export type InputBarProps = {
    execCommand: (cmd: string, params: string) => void,
    msgInfo: InputAlert,
    sendMessage: (message: string) => void,
    raiseInputAlert: (msgInfo: ?InputAlert) => void,
    isModerator: boolean,
    slowModeTime: number,
    roomStatus: RoomStatusType,
    preparedInputValue: string,
    toggledFeatures: FeaturesToggle
};

const InputBar = ({
    msgInfo,
    sendMessage,
    execCommand,
    raiseInputAlert,
    isModerator,
    slowModeTime,
    roomStatus,
    preparedInputValue,
    toggledFeatures
}: InputBarProps): React$Element<any> => {
    const [messageInput, setMessageInput] = useState('');
    const inputCharLimit = 500;
    const [showAlertTimeoutId, setShowAlertTimeoutId] = useState(null);
    const [isQuizManagementPopupOpen, setIsQuizManagementPopupOpen] =
        useState(false);
    const [isCloseRoomPopupOpen, setIsCloseRoomPopupOpen] = useState(false);
    const [slowModeTimer, setSlowModeTimer] = useState(0);
    const isRoomClosed = roomStatus === RoomStatus.CLOSED && !isModerator;
    const isRoomOpen = roomStatus === RoomStatus.OPEN;
    const inputAreaRef = useRef(null);
    const { showScrollButton, scrollFeedBottom } = useContext(ScrollButtonContext);

    useEffect(() => {
        slowModeTimer > 0 &&
            setTimeout(() => setSlowModeTimer(slowModeTimer - 1), 1000);
    }, [slowModeTimer]);

    useEffect(() => {
        if (preparedInputValue) {
            setMessageInput(preparedInputValue);
            if (inputAreaRef.current) {
                inputAreaRef.current.focus();
            }
        }
    }, [preparedInputValue]);

    const handleSend = useCallback(() => {
        if (messageInput.charAt(0) === '/') {
            const cmdArray = messageInput
                .trim()
                .split(/ (.*)/s)
                .filter((element) => element);
            const [cmd, params] = cmdArray;
            execCommand(cmd, params);
            setMessageInput('');
        } else {
            if (!isModerator && slowModeTimer > 0) {
                raiseInputAlert(
                    createInputAlert(
                        AlertTypes.INFO,
                        getSlowModeMessage(slowModeTimer)
                    )
                );
            } else {
                const messageToSend = messageInput.trim();
                if (messageToSend) {
                    sendMessage(messageToSend);
                    setSlowModeTimer(
                        isModerator || !toggledFeatures.slowMode ? 0 : slowModeTime
                    );
                }
                setMessageInput('');
            }
            setTimeout(scrollFeedBottom, 100);
        }
    }, [
        execCommand,
        isModerator,
        messageInput,
        raiseInputAlert,
        scrollFeedBottom,
        sendMessage,
        slowModeTime,
        slowModeTimer,
        toggledFeatures.slowMode
    ]);

    useDidMountEffect(() => {
        if (msgInfo) {
            const id = setTimeout(() => {
                raiseInputAlert(null);
            }, msgInfo.text.length * 50);
            if (showAlertTimeoutId) {
                clearTimeout(showAlertTimeoutId);
            }
            setShowAlertTimeoutId(id);
            return () => {
                clearTimeout(id);
            };
        }
    }, [msgInfo]);

    const handleChange = useCallback((event, emoji = '') => {
        if (emoji) {
            setMessageInput((state) => state + emoji);
        } else {
            setMessageInput(event.target.value.slice(0, inputCharLimit));
        }
    }, []);

    const handleKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
            }
        },
        [handleSend]
    );

    const closeQuizManagementPopupCallback = useCallback(
        () => setIsQuizManagementPopupOpen(false),
        []
    );

    const closeCloseRoomPopupCallback = useCallback(
        () => setIsCloseRoomPopupOpen(false),
        []
    );

    const changeQuizManagementPopupStateCallback = useCallback(() => {
        if (!isQuizManagementPopupOpen) {
            execCommand('/quiz_list', '');
            if (isCloseRoomPopupOpen) {
                closeCloseRoomPopupCallback();
            }
        }
        setIsQuizManagementPopupOpen((lastState) => !lastState);
    }, [
        closeCloseRoomPopupCallback,
        execCommand,
        isCloseRoomPopupOpen,
        isQuizManagementPopupOpen
    ]);

    const execStickyCallback = useCallback(
        () => execCommand('/sticky', messageInput),
        [messageInput, execCommand]
    );

    const execCloseRoomCallback = useCallback(
        () => execCommand('/close', ''),
        [execCommand]
    );

    const execChangeRoomStatus = useCallback(() => {
        if (isRoomOpen) {
            if (isQuizManagementPopupOpen) {
                closeQuizManagementPopupCallback();
            }
            setIsCloseRoomPopupOpen(true);
        } else {
            execCommand('/open', '');
        }
        return !isRoomOpen;
    }, [
        isRoomOpen,
        isQuizManagementPopupOpen,
        closeQuizManagementPopupCallback,
        execCommand
    ]);

    return (
        <InputWrapper>
            {showScrollButton ? (
                <ScrollDownButton
                    commandFunc={scrollFeedBottom}
                    Icon={ScrollDownIcon}
                    alt="ScrollDownIcon"
                />
            ) : null}
            {msgInfo ? (
                <AlertMessageWrapper>
                    <AlertMessage text={msgInfo.text} type={msgInfo.type} />
                </AlertMessageWrapper>
            ) : null}
            <InputTextAreaContainer
                value={messageInput}
                placeholder={__('Write_message')}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                isModerator={isModerator}
                disabled={isRoomClosed}
                inputRef={inputAreaRef}
            />
            {isModerator ? (
                <ButtonWrapper>
                    {toggledFeatures.topWidgets.mainMessage.changeText ? (
                        <StickyCmdButton onClick={execStickyCallback}>
                            {__('Write_banner')}
                        </StickyCmdButton>
                    ) : null}

                    {toggledFeatures.topWidgets.quiz ? (
                        <React.Fragment>
                            <QuizPopupButton
                                onClick={changeQuizManagementPopupStateCallback}>
                                {__('Quiz')}
                            </QuizPopupButton>
                            {isQuizManagementPopupOpen ? (
                                <QuizManagementPopup
                                    execCommand={execCommand}
                                    closePopup={closeQuizManagementPopupCallback}
                                    isOpen={isQuizManagementPopupOpen}
                                />
                            ) : null}
                        </React.Fragment>
                    ) : null}

                    {toggledFeatures.roomStatus ? (
                        <React.Fragment>
                            <VerticalSpacer />
                            <ToggleSwitch
                                action={execChangeRoomStatus}
                                value={isRoomOpen}
                            />
                            {isCloseRoomPopupOpen ? (
                                <CloseRoomPopupWrapper>
                                    <ValidationPopup
                                        action={execCloseRoomCallback}
                                        closePopup={closeCloseRoomPopupCallback}
                                        question={__('Close_room_question')}
                                        yesLabel={__('Yes')}
                                        noLabel={__('No')}
                                    />
                                </CloseRoomPopupWrapper>
                            ) : null}
                        </React.Fragment>
                    ) : null}
                </ButtonWrapper>
            ) : null}
        </InputWrapper>
    );
};

export default InputBar;
