// @flow

import React from 'react';

import FiraSansBlack from 'assets/fonts/FiraSans-Black.ttf';
import FiraSansBold from 'assets/fonts/FiraSans-Bold.ttf';
import FiraSansLight from 'assets/fonts/FiraSans-Light.ttf';
import FiraSansMedium from 'assets/fonts/FiraSans-Medium.ttf';
import FiraSansRegular from 'assets/fonts/FiraSans-Regular.ttf';
import YantramanavBlack from 'assets/fonts/Yantramanav-Black.ttf';
import YantramanavBold from 'assets/fonts/Yantramanav-Bold.ttf';
import YantramanavLight from 'assets/fonts/Yantramanav-Light.ttf';
import YantramanavMedium from 'assets/fonts/Yantramanav-Medium.ttf';
import YantramanavRegular from 'assets/fonts/Yantramanav-Regular.ttf';
import YantramanavThin from 'assets/fonts/Yantramanav-Thin.ttf';
import GermaniaOneRegular from 'assets/fonts/GermaniaOne-Regular.ttf';

const FontLoader = (): React$Element<any> => {
    const fontsLoaderStr = `
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavBlack}) format('truetype');
            font-weight: bolder;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavBold}) format('truetype');
            font-weight: bold;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavMedium}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavRegular}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavLight}) format('truetype');
            font-weight: lighter;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-Yantramanav';
            src: url(${YantramanavThin}) format('truetype');
            font-weight: 100;
            font-style: normal;
        }

        @font-face {
            font-family: 'chatamax-GermaniaOne';
            src: url(${GermaniaOneRegular}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }

       
        @font-face {
            font-family: 'chatamax-FiraSans';
            src: url(${FiraSansBlack}) format('truetype');
            font-weight: bolder;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-FiraSans';
            src: url(${FiraSansBold}) format('truetype');
            font-weight: bold;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-FiraSans';
            src: url(${FiraSansMedium}) format('truetype');
            font-weight: 500;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-FiraSans';
            src: url(${FiraSansRegular}) format('truetype');
            font-weight: normal;
            font-style: normal;
        }
        
        @font-face {
            font-family: 'chatamax-FiraSans';
            src: url(${FiraSansLight}) format('truetype');
            font-weight: lighter;
            font-style: normal;
        }

      
    `;

    return <style>{fontsLoaderStr}</style>;
};

export default FontLoader;
