// @flow

import type { Message } from '../../../types/message';
import type { Quiz } from '../../../types/quiz';
import type { UserRoleType, UserStatusType } from '../../../types/user';

import type { RoomStatusType } from '../../../types/room';

import * as actions from '../../actions/chatActions';

const handleReceiveMsg = (store: any, payload: Message) => {
    store.dispatch(actions.receiveMessage(payload));
};

type SetMainMsgPayload = {
    msg: string
};

const handleSetMainMsg = (store: any, payload: SetMainMsgPayload) => {
    const { msg } = payload;
    store.dispatch(actions.setMainMessage(msg));
};

type RemoveMsgPayload = {
    msgId: string
};

const handleRemoveMsg = (store: any, payload: RemoveMsgPayload) => {
    const { msgId } = payload;
    store.dispatch(actions.removeMessage(msgId));
};

type ChangeUserRolePayload = {
    userRole: UserRoleType
};

const handleChangeUserRole = (store: any, payload: ChangeUserRolePayload) => {
    const { userRole } = payload;
    store.dispatch(actions.changeUserRole(userRole));
};

type SetQuizPayload = {
    quiz: ?Quiz
};

const handleSetQuiz = (store: any, payload: SetQuizPayload) => {
    const { id, question, responses, correctAnswerIndex, status } =
        payload.quiz ?? {};
    const currentQuiz: Quiz = {
        id,
        question,
        responses,
        correctAnswerIndex,
        status,
        isCurrentQuiz: true
    };
    store.dispatch(actions.setQuiz(payload.quiz ? currentQuiz : null));
};

type SetSlowModePayload = {
    time: number
};

const handleSetSlowMode = (store: any, payload: SetSlowModePayload) => {
    const { time } = payload;
    store.dispatch(actions.setSlowMode(time / 1000));
};

type RemoveUserMsgPayload = {
    nickname: string
};

const handleRemoveUserMsg = (store: any, payload: RemoveUserMsgPayload) => {
    const { nickname } = payload;
    store.dispatch(actions.removeUserMessage(nickname));
};

type SetRoomStatusPayload = { status: RoomStatusType };

const handleSetRoomStatus = (store: any, payload: SetRoomStatusPayload) => {
    const { status } = payload;
    store.dispatch(actions.setStatus(status));
};

type ChangeMemberInfoPayload = {
    nickname: string,
    role: ?UserRoleType,
    status: ?UserStatusType
};

const handleChangeMemberInfo = (store: any, payload: ChangeMemberInfoPayload) => {
    const { nickname, role, status } = payload;
    store.dispatch(actions.changeMemberInfo(nickname, role, status));
};

// type SetNbUserPayload = {
//     nb: number
// };

// const handleSetNbUser = (store: any, payload: SetNbUserPayload) => {
//     const { nb } = payload;
//     store.dispatch(actions.setNbUser(nb));
// };

export const redisNotifActions = {
    RECEIVE_MESSAGE: handleReceiveMsg,
    SET_MAIN_MESSAGE: handleSetMainMsg,
    REMOVE_MESSAGE: handleRemoveMsg,
    CHANGE_USER_ROLE: handleChangeUserRole,
    SET_QUIZ: handleSetQuiz,
    SET_SLOW_MODE: handleSetSlowMode,
    REMOVE_USER_MSG: handleRemoveUserMsg,
    SET_ROOM_STATUS: handleSetRoomStatus,
    CHANGE_MEMBER_INFO: handleChangeMemberInfo
    // SET_NB_USER: handleSetNbUser
};
