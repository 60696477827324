// @flow

import type { Message } from '../types/message';

import React from 'react';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';
import MessageComponentContainer from '../containers/MessageComponentContainer.jsx';
import { ScrollButtonContext } from './ChatRoot.jsx';
import Formatter, { isSameDay } from '../utils/formatter';

const FeedWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    flex: 1;
    padding-bottom: 2px;
    scrollbar-color: ${({ theme }) => `${theme.color.scrollBar} transparent`};
    scrollbar-width: '5px';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.color.scrollBar};
        border-radius: 6px;
    }

    ::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }
`;

const FeedBottom = styled.div`
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    bottom: 0;
`;

const Separator = styled.div`
    height: ${({ theme }) => theme.font.msgSeparator.size};
    display: flex;
    align-items: center;
    padding: 10px 5px;
`;

const LineSeparator = styled.div`
    width: 100%;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.secondBackground};
    line-height: 0;

    & span {
        background: ${({ theme }) => theme.color.mainBackground};
        color: ${({ theme }) => theme.color.mainText};
        font-size: ${({ theme }) => theme.font.msgSeparator.size};
        padding: 0 5px;
        font-weight: ${({ theme }) => theme.font.msgSeparator.weight};
    }
`;

export type FeedProps = {
    list: Message[],
    isRoomClosed: boolean,
    bottomFeedRef: any,
    feedRef: any,
    isMsgDated: boolean
};

const Feed = ({
    list,
    isRoomClosed,
    bottomFeedRef,
    feedRef,
    isMsgDated
}: FeedProps): React$Element<any> => {
    const { setShowScrollButton } = useContext(ScrollButtonContext);
    useEffect(() => {
        const element = feedRef.current;
        element?.addEventListener('scroll', () => {
            setShowScrollButton(element.scrollTop < -40);
        });
        return () => {
            element?.removeEventListener('scroll', () => {}, {});
        };
    }, [feedRef, setShowScrollButton]);

    const getMessageComponent = (message: Message, index: number) => {
        let day = '';
        const needSeparator =
            isMsgDated &&
            (index === list.length - 1 ||
                !isSameDay(message.timestamp, list[index + 1].timestamp));
        if (needSeparator) {
            day = Formatter.tsToDate(message.timestamp);
        }
        return (
            <React.Fragment key={`${message.id}-fragment`}>
                <MessageComponentContainer
                    key={`${message.id}-container`}
                    message={message}
                />
                {day ? (
                    <Separator key={`${message.id}-separator`}>
                        <LineSeparator key={`${message.id}-lineSeparator`}>
                            <span key={`${message.id}-span`}>{day}</span>
                        </LineSeparator>
                    </Separator>
                ) : null}
            </React.Fragment>
        );
    };

    return (
        <FeedWrapper ref={feedRef}>
            <FeedBottom ref={bottomFeedRef} />
            {isRoomClosed
                ? null
                : list.map((message, index) => getMessageComponent(message, index))}
        </FeedWrapper>
    );
};

export default Feed;
