// @flow

import { createSelector } from 'reselect';
import { UserRoles } from '../../types/user';
import { selectUserRole } from '../reducers/userReducer';

const selectQuizComponentProps: any = createSelector(
    [selectUserRole],
    (userRole) => ({
        quizDisabled: userRole !== UserRoles.WRITER
    })
);

export default selectQuizComponentProps;
