// @flow

import { applyMiddleware, compose, createStore } from 'redux';

import { getRootReducers } from '../reducers';

import { chatMiddleware } from './chatMiddleware';
import type { AppState } from '../../types/states';
import { initialChatState } from '../reducers/chatReducer';
import { initialUserState } from '../reducers/userReducer';
import { initialQuizState } from '../reducers/quizReducer';
import { initialRoomState } from '../reducers/roomReducer';

export const configureStore = (): any => {
    let store = null;
    const middlewares = applyMiddleware(chatMiddleware);
    const rootReducer = getRootReducers();

    const initialState: AppState = {
        chatApp: initialChatState,
        roomState: initialRoomState,
        userState: initialUserState,
        quizState: initialQuizState
    };
    const composeEnhancers =
        typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;
    store = createStore(rootReducer, initialState, composeEnhancers(middlewares));
    return store;
};
