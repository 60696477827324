// @flow
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
    cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
    background-color: ${({ theme }) => theme.color.secondBackground};
    padding: 7px;
    border-radius: 15px;
`;

type ButtonCmdActionProps = {
    className?: string,
    commandFunc: () => void,
    Icon: any,
    alt: string,
    disabled?: boolean
};

const ButtonCmdAction = ({
    className = '',
    commandFunc,
    Icon,
    alt,
    disabled = false
}: ButtonCmdActionProps): React$Element<any> => {
    return (
        <IconWrapper
            $disabled={disabled}
            className={className}
            onClick={disabled ? () => {} : commandFunc}>
            <Icon height="100%" alt={alt} />
        </IconWrapper>
    );
};

export default ButtonCmdAction;
