// @flow

import React from 'react';
import { useCallback, useEffect } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { chatModoCommands } from '../../redux/middlewares/utils/chatModoCommands';
// $FlowIgnore[cannot-resolve-module]
import '@webscopeio/react-textarea-autocomplete/style.css';
import styled from 'styled-components';
import EmojiPicker from './EmojiPicker';

const TextAreaWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.inputBackground};
    overflow: visible;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
`;

const StyledTextArea = styled(ReactTextareaAutocomplete)`
    width: 100%;
    background-color: transparent;
    color: ${({ theme }) => theme.color.mainText};
    padding: 10px 5px 10px 10px;
    box-sizing: border-box;
    border: none;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;

    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder {
        font-size: ${({ theme }) => theme.font.messageInput.size};
    }

    & .rta__textarea {
        font-size: ${({ theme }) => theme.font.messageInput.size};
    }
`;

const EmojiButtonWrapper = styled.div`
    padding: 0 5px 0 5px;
`;

const Loading = ({ data }) => <div>Loading</div>;
const CmdSugg = ({ entity, selected }) => {
    return <div>{entity}</div>;
};
const UserMention = ({ entity, selected }) => {
    return <div>{entity}</div>;
};

export type InputTextAreaProps = {
    handleKeyDown: (e: any) => void,
    handleChange: (e: any, emoji?: ?string) => void,
    value: string,
    placeholder: string,
    feedUsers: Array<string>,
    isModerator: boolean,
    disabled: boolean,
    inputRef: any
};

const InputTextArea = ({
    handleKeyDown,
    handleChange,
    value,
    placeholder,
    feedUsers,
    isModerator,
    disabled,
    inputRef
}: InputTextAreaProps): React$Element<any> => {
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleEmojiChange = useCallback(
        (event, emoji) => {
            handleChange(event, emoji);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
        [handleChange, inputRef]
    );

    return (
        <TextAreaWrapper>
            <StyledTextArea
                innerRef={(textarea) => {
                    inputRef.current = textarea;
                }}
                containerStyle={{ width: '100%', fontSize: '14px' }}
                rows={1}
                placeholder={placeholder}
                movePopupAsYouType={true}
                minChar={0}
                value={value}
                disabled={disabled}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                loadingComponent={Loading}
                trigger={{
                    '/': {
                        dataProvider: (token) => {
                            if (isModerator) {
                                return Object.keys(chatModoCommands).filter(
                                    (cmd) => cmd.indexOf('/' + token) === 0
                                );
                            }
                            return [];
                        },
                        component: CmdSugg,
                        output: (item, trigger) => {
                            return item;
                        }
                    },
                    '@': {
                        dataProvider: (token) => {
                            return feedUsers
                                .filter((cmd) => cmd.indexOf(token) === 0)
                                .slice(0, 5);
                        },
                        component: UserMention,
                        output: (item, trigger) => {
                            return item;
                        }
                    }
                }}
            />
            <EmojiButtonWrapper>
                <EmojiPicker disabled={disabled} handleChange={handleEmojiChange} />
            </EmojiButtonWrapper>
        </TextAreaWrapper>
    );
};

export default InputTextArea;
