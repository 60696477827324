// @flow

import { useMemo } from 'react';

type PropsToBeGrouped = {
    ownProps?: AnyObject,
    selectedProps?: AnyObject,
    actionsProps?: AnyObject,
    otherProps?: AnyObject
};

const useMergeProps = ({
    ownProps = {},
    selectedProps = {},
    actionsProps = {},
    otherProps = {}
}: PropsToBeGrouped): empty =>
    useMemo(
        () => ({
            ...ownProps,
            ...selectedProps,
            ...actionsProps,
            ...otherProps
        }),
        [actionsProps, ownProps, selectedProps, otherProps]
    );

export default useMergeProps;
