// @flow

import { combineReducers } from 'redux';

import * as chatAppReducers from './chatReducer';
import * as userReducer from './userReducer';
import * as quizReducer from './quizReducer';
import * as roomReducer from './roomReducer';

export const getRootReducers = (): any =>
    combineReducers({
        chatApp: chatAppReducers.chatAppReducer,
        roomState: roomReducer.roomReducer,
        userState: userReducer.userReducer,
        quizState: quizReducer.quizReducer
    });
