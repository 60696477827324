// @flow

import React from 'react';

import useMergeProps from '../hooks/useMergeProps';
import { useSelector } from 'react-redux';
import type { MembersListProps } from '../components/feed_components/MembersList.jsx';
import MembersList from '../components/feed_components/MembersList.jsx';
import selectMembersListProps from '../redux/selectors/selectMembersListProps';

type MembersListOwnProps = {
    isDisplayVertical?: boolean
};

const MembersListContainer = (
    ownProps: MembersListOwnProps
): React$Element<(_0: MembersListProps) => React$Element<any>> => {
    const selectedProps = useSelector(selectMembersListProps);

    const enhancedProps = useMergeProps({ selectedProps, ownProps });

    return <MembersList {...enhancedProps} />;
};

export default MembersListContainer;
