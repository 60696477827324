// @flow

import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const ToggleSwitchWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SwitchWrapper = styled.div`
    position: relative;
    width: 50px;
    display: inline-block;
    text-align: left;
`;

const Label = styled.label`
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
`;

const InnerSpan = styled.span`
     {
        display: block;
        width: 200%;
        margin-left: ${({ $checked }) => ($checked ? '0' : '-100%')};
        transition: margin 0.3s ease-in 0s;
    }
    &:after,
    &:before {
        float: left;
        width: 50%;
        height: 28px;
        padding: 0;
        line-height: 36px;
        color: #fff;
        font-weight: bold;
        box-sizing: border-box;
    }
    &:before {
        content: '';
        padding-left: 10px;
        background-color: #060;
        color: #fff;
    }
    &:after {
        content: '';
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
    }
`;

const Switch = styled.span`
    display: block;
    width: 18px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${({ $checked }) => ($checked ? '0px' : '22px')};
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
`;

type ToggleSwitchProps = {
    value: boolean,
    action: () => boolean
};

const ToggleSwitch = ({ value, action }: ToggleSwitchProps): React$Element<any> => {
    const [isChecked, setIsChecked] = useState(value);

    useEffect(() => {
        setIsChecked(value);
    }, [value]);

    const handleClick = (e) => {
        if (action()) {
            setIsChecked((state) => !state);
        }
    };

    return (
        <ToggleSwitchWrapper>
            <SwitchWrapper>
                <Label onClick={handleClick}>
                    <InnerSpan $checked={isChecked} />
                    <Switch $checked={isChecked} />
                </Label>
            </SwitchWrapper>
        </ToggleSwitchWrapper>
    );
};

export default ToggleSwitch;
