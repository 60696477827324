// @flow

import type { Quiz } from '../../../types/quiz';
import type { jsonrpcRequest } from '../../../types/jsonrpc';

type cmdResponse = jsonrpcRequest | null;

const execQuizAnswer = (
    store: any,
    params: string,
    roomName: string
): cmdResponse => {
    const quiz: Quiz = store.getState()?.quizState?.currentQuiz;
    if (quiz && params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            const answerIndex = parseInt(paramsArray[0]);
            if (answerIndex >= 0 && answerIndex < quiz.responses.length) {
                return {
                    jsonrpc: '2.0',
                    method: 'Answer',
                    params: {
                        quizId: quiz.id,
                        answerIndex
                    },
                    id: 'AnswerQuizId',

                    ns: 'core/chat/quiz'
                };
            }
        }
    }
    return null;
};

export const chatUserCommands = {
    '/quiz_answer': execQuizAnswer
};
