// @flow

import React from 'react';
import { useState } from 'react';
import FormInput from '../common/FormInput';
import styled from 'styled-components';
import { Cross, BackIcon } from 'assets/images';
import ButtonCmdAction from '../common/ButtonCmdAction.jsx';
import {
    PopupHeaderWrapper,
    PopupHeaderText,
    Label,
    LabelText
} from '../common/sharedStyledComponent';
import type { Quiz } from '../../types/quiz';
import { __ } from '../../utils/translator';

const SendButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-end;
`;

const SendButton = styled.input`
    color: ${({ theme }) => theme.color.secondText};
    background-color: ${({ theme }) => theme.color.formButton};
    width: 150px;
    margin-right: 20px;

    height: 40px;
    border-radius: 6px;
    border: 0px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.font.generalButton.weight};
    font-size: ${({ theme }) => theme.font.generalButton.size};
`;

const ErrorText = styled.div`
    color: ${({ theme }) => theme.color.errorBackground};
`;

const BackButton = styled(ButtonCmdAction)`
    height: 20px;
    margin: 10px 0 0 10px;
`;

const CloseButton = styled(ButtonCmdAction)`
    height: 15px;
    margin: 10px 10px 0 0;
`;

const AnswerInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const NoCorrectAnswerRadioButton = styled.input`
    margin-right: 0.7rem;
`;

const CorrectAnswerRadioButton = styled(NoCorrectAnswerRadioButton)`
    margin-top: 25px;
`;

type QuizPopupProps = {
    execCommand: (cmd: string, params: string) => void,
    closePopup: () => void,
    changePopup: () => void,
    quizToEdit: ?Quiz
};

const QuizCreatePopup = ({
    execCommand,
    closePopup,
    changePopup,
    quizToEdit
}: QuizPopupProps): React$Element<any> => {
    const {
        id: quizId,
        correctAnswerIndex,
        question,
        responses,
        isCurrentQuiz
    } = quizToEdit ?? {};
    const [choiceOne, choiceTwo, choiceThree, choiceFour] = responses ?? [];

    const [questionInput, setQuestionInput] = useState(question ?? '');
    const [correctAnswerIndexInput, setCorrectAnswerIndexInput] = useState(
        correctAnswerIndex?.toString() ?? ''
    );
    const [answerOneInput, setAnswerOneInput] = useState(choiceOne ?? '');
    const [answerTwoInput, setAnswerTwoInput] = useState(choiceTwo ?? '');
    const [answerThreeInput, setAnswerThreeInput] = useState(choiceThree ?? '');
    const [answerFourInput, setAnswerFourInput] = useState(choiceFour ?? '');
    const [showError, setShowError] = useState(false);

    const handleOnChange = (e, setInput) => {
        setInput(e.target.value);
        setShowError(false);
    };

    const handleOptionnalAnswerChange = (e, setInput) => {
        handleOnChange(e, setInput);
        if (!e.target.value) {
            setCorrectAnswerIndexInput('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!questionInput || !answerOneInput || !answerTwoInput) {
            setShowError(true);
        } else {
            let cmdParams = '';
            cmdParams += `{${questionInput}}`;
            cmdParams += ` {${correctAnswerIndexInput}}`;
            cmdParams += ` {${answerOneInput}}`;
            cmdParams += ` {${answerTwoInput}}`;
            cmdParams += answerThreeInput ? ` {${answerThreeInput}}` : '';
            cmdParams += answerFourInput ? ` {${answerFourInput}}` : '';
            if (quizId) {
                cmdParams = `{${quizId}} ${cmdParams}`;
                execCommand('/quiz_edit', cmdParams);
            } else {
                execCommand('/quiz_create', cmdParams);
            }
            changePopup();
        }
    };

    return (
        <div>
            <PopupHeaderWrapper>
                <BackButton
                    alt="BackToListIcon"
                    commandFunc={changePopup}
                    Icon={BackIcon}
                />
                <PopupHeaderText>
                    {quizId ? __('Edit_quiz') : __('Create_quiz')}
                </PopupHeaderText>
                <CloseButton
                    Icon={Cross}
                    alt="QuizManagementCross"
                    commandFunc={closePopup}
                />
            </PopupHeaderWrapper>
            <form onSubmit={handleSubmit}>
                <div>
                    <FormInput
                        label={__('Question')}
                        value={questionInput}
                        handleChange={(e) => handleOnChange(e, setQuestionInput)}
                        disabled={isCurrentQuiz}
                    />
                    <AnswerInputWrapper>
                        <FormInput
                            label={__('Answer') + ' 1'}
                            value={answerOneInput}
                            handleChange={(e) =>
                                handleOnChange(e, setAnswerOneInput)
                            }
                            disabled={isCurrentQuiz}
                        />
                        <CorrectAnswerRadioButton
                            type="radio"
                            value={'0'}
                            checked={correctAnswerIndexInput === '0'}
                            onChange={(e) =>
                                handleOnChange(e, setCorrectAnswerIndexInput)
                            }
                        />
                    </AnswerInputWrapper>
                    <AnswerInputWrapper>
                        <FormInput
                            label={__('Answer') + ' 2'}
                            value={answerTwoInput}
                            handleChange={(e) =>
                                handleOnChange(e, setAnswerTwoInput)
                            }
                            disabled={isCurrentQuiz}
                        />
                        <CorrectAnswerRadioButton
                            type="radio"
                            value={'1'}
                            checked={correctAnswerIndexInput === '1'}
                            onChange={(e) =>
                                handleOnChange(e, setCorrectAnswerIndexInput)
                            }
                        />
                    </AnswerInputWrapper>
                    <AnswerInputWrapper>
                        <FormInput
                            label={`${__('Answer')} 3 (${__('Optional')})`}
                            value={answerThreeInput}
                            handleChange={(e) =>
                                handleOptionnalAnswerChange(e, setAnswerThreeInput)
                            }
                            disabled={isCurrentQuiz}
                        />
                        <CorrectAnswerRadioButton
                            type="radio"
                            value={'2'}
                            checked={correctAnswerIndexInput === '2'}
                            onChange={(e) =>
                                handleOptionnalAnswerChange(
                                    e,
                                    setCorrectAnswerIndexInput
                                )
                            }
                            disabled={!answerThreeInput}
                        />
                    </AnswerInputWrapper>
                    <AnswerInputWrapper>
                        <FormInput
                            label={`${__('Answer')} 4 (${__('Optional')})`}
                            value={answerFourInput}
                            handleChange={(e) =>
                                handleOnChange(e, setAnswerFourInput)
                            }
                            disabled={isCurrentQuiz}
                        />
                        <CorrectAnswerRadioButton
                            type="radio"
                            value={'3'}
                            checked={correctAnswerIndexInput === '3'}
                            onChange={(e) =>
                                handleOnChange(e, setCorrectAnswerIndexInput)
                            }
                            disabled={!answerFourInput}
                        />
                    </AnswerInputWrapper>
                    {!(typeof correctAnswerIndex === 'number') ? (
                        <AnswerInputWrapper>
                            <Label>
                                <LabelText>{__('No_right_answer')}</LabelText>
                            </Label>
                            <NoCorrectAnswerRadioButton
                                type="radio"
                                value={''}
                                checked={!correctAnswerIndexInput}
                                onChange={(e) =>
                                    handleOnChange(e, setCorrectAnswerIndexInput)
                                }
                            />
                        </AnswerInputWrapper>
                    ) : null}
                </div>
                <SendButtonWrapper>
                    <SendButton
                        type="submit"
                        value={quizId ? __('Edit') : __('Create')}
                    />
                    {showError ? (
                        <ErrorText>{__('Required_field')}</ErrorText>
                    ) : null}
                </SendButtonWrapper>
            </form>
        </div>
    );
};

export default QuizCreatePopup;
