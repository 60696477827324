// @flow

import type { MemberInfo } from '../../types/room';

import React from 'react';
import { useContext } from 'react';
import { CenteredRow, Column, Row } from '../common/sharedStyledComponent';
import styled from 'styled-components';
import { UserStatus } from '../../types/user';
import { css } from 'styled-components';
import { AppContext } from '../ChatApp.jsx';
import { MembersAvatar } from '../../assets/images';

const RowListWrapper = styled(Row)`
    background-color: ${({ theme }) => theme.color.mainBackground};
    padding: 5px;
`;

const MembersColumnList = styled(Column)`
    background-color: ${({ theme }) => theme.color.mainBackground};
    padding: 5px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
`;

const MemberRow = styled(Column)`
    flex-basis: 100%;
    flex: 1;
`;

const StatusDot = styled.div`
    height: ${({ theme }) => theme.size.memberDotRadius};
    width: ${({ theme }) => theme.size.memberDotRadius};
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px 0 5px;

    ${({ $isConnected, theme }) => css`
        background-color: ${$isConnected
            ? theme.color.connectedDot
            : theme.color.discoDot};
    `}
`;

const NicknameText = styled.div`
    font-size: ${({ theme }) => theme.font.memberNickname.size};
    color: ${({ theme }) => theme.color.mainText};
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.memberNickname.family};
    font-weight: ${({ theme }) => theme.font.memberNickname.weight};
`;

type MemberProps = {
    member: MemberInfo,
    index: number,
    style?: Object
};

const MemberComponent = ({ member, index, style = {} }: MemberProps) => {
    const { applicationName } = useContext(AppContext);
    let MemberAvatar = null;
    if (applicationName) {
        const avatars = MembersAvatar[applicationName];
        if (avatars) {
            const nbLogo = avatars.length;
            MemberAvatar = avatars[index % nbLogo];
        }
    }
    return (
        <CenteredRow style={style}>
            {MemberAvatar ? (
                <MemberAvatar height="40px" style={{ padding: '5px' }} />
            ) : null}
            <StatusDot $isConnected={member.status === UserStatus.CONNECTED} />
            <NicknameText>{member.nickname}</NicknameText>
        </CenteredRow>
    );
};

export type MembersListProps = {
    members: Array<MemberInfo>,
    isDisplayVertical?: boolean
};

const MembersList = ({
    members,
    isDisplayVertical = false
}: MembersListProps): React$Element<any> => {
    return (
        <React.Fragment>
            {isDisplayVertical ? (
                <MembersColumnList>
                    {members.map((member, index) => (
                        <MemberComponent
                            member={member}
                            index={index}
                            key={member.nickname}
                            style={{ margin: '4px' }}
                        />
                    ))}
                </MembersColumnList>
            ) : (
                <RowListWrapper>
                    <MemberRow>
                        {members.map((member, index) =>
                            index % 2 === 0 ? (
                                <MemberComponent
                                    member={member}
                                    index={index}
                                    key={member.nickname}
                                />
                            ) : null
                        )}
                    </MemberRow>
                    <MemberRow>
                        {members.map((member, index) =>
                            index % 2 === 1 ? (
                                <MemberComponent
                                    member={member}
                                    index={index}
                                    key={member.nickname}
                                />
                            ) : null
                        )}
                    </MemberRow>
                </RowListWrapper>
            )}
        </React.Fragment>
    );
};

export default MembersList;
