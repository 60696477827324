// @flow

import * as actions from '../actions/chatActions';
import * as actionTypes from '../../types/actions';
import { chatModoCommands } from './utils/chatModoCommands';
import { chatUserCommands } from './utils/chatUserCommands';
import { UserRoles } from '../../types/user';
import { redisNotifActions } from './utils/redisNotifActions';
import { proxyResponses } from './utils/wspushResponses';
import type { UserRoleType } from '../../types/user';
import { createInputAlert } from '../../types/message';
import WsPushClient from './network/wspush';
import { METHODS, NameSpace } from './network/wspush';
import { has } from 'lodash';
import { __ } from '../../utils/translator';

const requestToJoinRoom = (roomName: string) => {
    WsPushClient.callAPI(METHODS.JOIN_ROOM, { roomName: roomName }, NameSpace.CHAT);
    WsPushClient.subscribe(false, `chatamax:${roomName}`);
};

const requestPrivateChannel = () => {
    WsPushClient.subscribe(true, `chat`);
};

type RedisNotification = {
    action: $Keys<typeof redisNotifActions>,
    payload: Object
};

const handleRedisNotif = (store: any, data: RedisNotification) => {
    const { action, payload } = data;
    const notifFunction = redisNotifActions[action];
    if (notifFunction) {
        notifFunction(store, payload);
    }
};

const handleSendMessage = (message: string, roomName: string) => {
    WsPushClient.callAPI(
        METHODS.SEND_MESSAGE,
        {
            roomName: roomName,
            msg: message
        },
        NameSpace.CHAT
    );
};

const handleExecCommand = (
    store: any,
    cmd: string,
    params: string,
    roomName,
    userRole: UserRoleType
) => {
    const commandModoRequestFunc = chatModoCommands[cmd];
    const commandUserRequestFunc = chatUserCommands[cmd];

    if (!commandModoRequestFunc && !commandUserRequestFunc) {
        store.dispatch(
            actions.raiseInputAlert(createInputAlert('ERROR', __('Cmd_not_exists')))
        );
    } else {
        let commandRequest = null;

        if (commandModoRequestFunc) {
            if (userRole === UserRoles.MODERATOR) {
                commandRequest = commandModoRequestFunc(params, roomName);
            } else {
                return store.dispatch(
                    actions.raiseInputAlert(
                        createInputAlert('ERROR', __('Required_rights'))
                    )
                );
            }
        } else {
            commandRequest = commandUserRequestFunc(store, params, roomName);
        }

        if (!commandRequest) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert('ERROR', __('Cmd_syntax') + cmd)
                )
            );
        } else {
            const { method, params, ns } = commandRequest;
            WsPushClient.callAPI(method, params, ns);
        }
    }
};

export const chatMiddleware: any = (store) => (next) => (action) => {
    const state = store.getState();
    const { chatApp, userState, roomState } = state;
    const { isConnected, env, operator } = chatApp;
    const { userRole } = userState;
    const { roomName } = roomState;
    var authRetryNb = 0;

    const isUserConnected = isConnected && userRole !== UserRoles.READER;

    if (action.type === actionTypes.START_CONNECTING) {
        const {
            socketConnection,
            generateToken,
            setConnected,
            setAuthenticated,
            setAuthenticate,
            setLastJwt
        } = WsPushClient;
        const { authenticated, connected } = WsPushClient.getInfos();

        socketConnection(env, operator, (data) => {
            const { id, error } = data ?? {};

            // Connection msg
            if (has(data, 'connected')) {
                setConnected(data.connected);

                if (data.connected && !connected) {
                    generateToken().then((token) => {
                        if (token) {
                            setAuthenticate(token);
                        } else {
                            requestToJoinRoom(roomName);
                            store.dispatch(actions.connectionEstablished());
                        }
                    });
                }
            }

            // Authentication msg
            if (has(data, 'authenticated')) {
                setAuthenticated(data.authenticated);

                if (data.authenticated && !authenticated) {
                    requestToJoinRoom(roomName);
                    requestPrivateChannel();
                    store.dispatch(actions.connectionEstablished());
                } else if (!data.authenticated && authenticated) {
                    generateToken().then((token) => {
                        if (token) {
                            setAuthenticate(token);
                        } else {
                            requestToJoinRoom(roomName);
                            store.dispatch(actions.connectionEstablished());
                        }
                    });
                }
            }

            // Authentification error
            if (id && id.includes('auth') && error) {
                setLastJwt(null);
                generateToken().then((token) => {
                    if (token) {
                        if (authRetryNb < 1) {
                            authRetryNb++;
                            setAuthenticate(token);
                        } else {
                            requestToJoinRoom(roomName);
                            store.dispatch(actions.connectionEstablished());
                        }
                    }
                });
            }

            // Other method response
            if (id) {
                const method = id.split('-')[0];
                const handleResposeFunc = proxyResponses[method];
                if (handleResposeFunc) {
                    handleResposeFunc(store, data, error);
                }
                // Notification redis
            } else {
                if (data) {
                    handleRedisNotif(store, data);
                }
            }
        });
    }

    if (action.type === actionTypes.SEND_MESSAGE) {
        if (isUserConnected) {
            handleSendMessage(action.payload.message, roomName);
        } else {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert('ERROR', __('Connect_to_send_msg'))
                )
            );
        }
    }

    if (action.type === actionTypes.EXEC_COMMAND) {
        if (isUserConnected) {
            handleExecCommand(
                store,
                action.payload.command,
                action.payload.params,
                roomName,
                userRole
            );
        } else {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert('ERROR', __('Connect_to_send_msg'))
                )
            );
        }
    }

    return next(action);
};
