// @flow

export type ActionType = {
    type: string,
    payload: Object
};

export const START_CONNECTING = 'START_CONNECTING';
export const CONNECTION_ESTABLISHED = 'CONNECTION_ESTABLISHED';
export const CONNECTION_FAILED = 'CONNECTION_FAILED';
export const CONFIGURE_APP = 'CONFIGURE_APP';
export const RECEIVE_JOIN_INFO = 'RECEIVE_JOIN_INFO';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const EXEC_COMMAND = 'EXEC_COMMAND';
export const RAISE_INPUT_ALERT = 'RAISE_INPUT_ALERT';
export const STORE_QUIZ_LIST = 'STORE_QUIZ_LIST';
export const SET_MAIN_MESSAGE = 'SET_MAIN_MESSAGE';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const SET_QUIZ = 'SET_QUIZ';
export const CHANGE_QUIZ_STATUS = 'CHANGE_QUIZ_STATUS';
export const SET_SLOW_MODE = 'SET_SLOW_MODE';
export const REMOVE_USER_MSG = 'REMOVE_USER_MSG';
export const SET_STATUS = 'SET_STATUS';
export const SET_TYPE = 'SET_TYPE';
export const SET_PREPARED_INPUT = 'SET_PREPARED_INPUT';
export const CHANGE_MEMBER_INFO = 'CHANGE_MEMBER_INFO';
export const SET_NB_USER = 'SET_NB_USER';
