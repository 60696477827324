// @flow

import Formatter from './formatter';
import { __ } from './translator';

const convertBanTime = (time) => {
    if (time > 3600 * 24 * 365 * 1000) {
        return __('Definitively');
    }
    return Formatter.tsToStringTime(time);
};

export const getBannedMessage = (
    reason: string,
    time: number,
    reasonMsg: string
): string => {
    const BanReasonSentence = Object.freeze({
        Flooding: __('Flooding'),
        ByModerator: __('Moderator')
    });
    const banTime = convertBanTime(time);
    const reasonSentence = BanReasonSentence[reason];
    // prettier-ignore
    let message = `${__('Not_authorized_banned %1$s Because_of %2$s', [ banTime, reasonSentence ])}`;
    message += reasonMsg ? ` ${__('For_the_reason')} : ${reasonMsg}.` : '.';
    return message;
};
