// @flow

import type { Quiz } from '../../types/quiz';
import React from 'react';
import { useCallback, useState } from 'react';
import ButtonCmdAction from '../common/ButtonCmdAction';
import { Check, DeleteIcon, EditIcon, WinnerIcon } from 'assets/images';
import styled from 'styled-components';
import { css } from 'styled-components';
import type { InputAlert } from '../../types/message';
import { AlertTypes, createInputAlert } from '../../types/message';
import { __ } from '../../utils/translator';

const QuizElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

    ${({ $index, theme }) => css`
        background-color: ${$index % 2 ? 'transparent' : theme.color.quizSecondBg};
    `}
`;

const QuizQuestionText = styled.div`
    font-size: ${({ theme }) => theme.font.quizListQuestion.size};
    font-weight: ${({ theme }) => theme.font.quizListQuestion.weight};
    color: ${({ theme }) => theme.color.mainText};
    max-width: 378px;
    display: flex;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const NbWinnersInput = styled.input`
    background-color: ${({ theme }) => theme.color.inputBackground};
    color: ${({ theme }) => theme.color.mainText};
    width: 30px;
    height: 15px;
`;

const QuizStyledButton = styled(ButtonCmdAction)`
    height: 15px;
    margin-left: 10px;
`;

export type QuizListElementProps = {
    quiz: Quiz,
    index?: number,
    execCommand: (cmd: string, params: string) => void,
    changePopup?: (quizToEdit: ?Quiz) => void,
    raiseInputAlert: (inputAlert: ?InputAlert) => void
};

const QuizListElement = ({
    quiz,
    index = 0,
    execCommand,
    changePopup = () => {},
    raiseInputAlert
}: QuizListElementProps): React$Element<any> => {
    const { question, id, isCurrentQuiz, correctAnswerIndex } = quiz;
    const [nbWinnersInput, setNbWinnersInput] = useState(1);

    const execActivateQuizCallback = useCallback(() => {
        execCommand('/quiz_activate', id);
    }, [id, execCommand]);

    const execDeleteQuizCallback = useCallback(
        () => execCommand('/quiz_delete', id),
        [id, execCommand]
    );

    const execEditQuizCallback = useCallback(
        () => changePopup(quiz),
        [changePopup, quiz]
    );

    const execDrawCallback = useCallback(() => {
        if (typeof correctAnswerIndex === 'number') {
            execCommand('/quiz_draw', nbWinnersInput.toString());
        } else {
            raiseInputAlert(
                createInputAlert(AlertTypes.ERROR, __('No_answer_setted'))
            );
        }
    }, [correctAnswerIndex, execCommand, nbWinnersInput, raiseInputAlert]);

    const handleNbChange = (e) => {
        setNbWinnersInput(e.target.value);
    };

    return (
        <QuizElementWrapper $index={index}>
            <QuizQuestionText>{question}</QuizQuestionText>
            <ButtonWrapper>
                {isCurrentQuiz ? (
                    <React.Fragment>
                        <NbWinnersInput
                            type={'number'}
                            min={1}
                            value={nbWinnersInput}
                            onChange={handleNbChange}
                        />
                        <QuizStyledButton
                            alt="DrawQuiz"
                            Icon={WinnerIcon}
                            commandFunc={execDrawCallback}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <QuizStyledButton
                            alt="ActivateQuiz"
                            Icon={Check}
                            commandFunc={execActivateQuizCallback}
                        />
                    </React.Fragment>
                )}
                <QuizStyledButton
                    alt="EditQuiz"
                    Icon={EditIcon}
                    commandFunc={execEditQuizCallback}
                />
                <QuizStyledButton
                    alt="DeleteQuiz"
                    Icon={DeleteIcon}
                    commandFunc={execDeleteQuizCallback}
                />
            </ButtonWrapper>
        </QuizElementWrapper>
    );
};

export default QuizListElement;
