// @flow

import React from 'react';

import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import { execCommand, setPreparedInput } from '../redux/actions/chatActions';
import MessageComponent from '../components/feed_components/MessageComponent';
import type { MessageComponentProps } from '../components/feed_components/MessageComponent.jsx';
import type { Message } from '../types/message';
import { useSelector } from 'react-redux';
import selectMessageComponentProps from '../redux/selectors/selectMessageComponentProps';

type MsgComponentOwnProps = {
    message: Message
};

const MsgComponentContainer = (
    ownProps: MsgComponentOwnProps
): React$Element<(_0: MessageComponentProps) => React$Element<any>> => {
    const actionsProps = useBinActionsCreator({
        execCommand,
        setPreparedInput
    });

    const selectedProps = useSelector(selectMessageComponentProps);

    const enhancedProps = useMergeProps({ ownProps, actionsProps, selectedProps });

    return <MessageComponent {...enhancedProps} />;
};

export default MsgComponentContainer;
