// @flow

import type { jsonrpcRequest } from '../../../types/jsonrpc';

type cmdResponse = jsonrpcRequest | null;

const execBan = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split('|')
            .reduce((acc, elem) => {
                if (elem) {
                    acc.push(elem.trim());
                }
                return acc;
            }, []);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'BanUser',
                params: {
                    nickname: paramsArray[0],
                    roomName: roomName
                },
                id: 'BanUserId',

                ns: 'core/chatamax'
            };
        }
        if (paramsArray.length === 2) {
            return {
                jsonrpc: '2.0',
                method: 'BanUser',
                params: {
                    nickname: paramsArray[0],
                    roomName: roomName,
                    banReason: paramsArray[1]
                },
                id: 'BanUserId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execTo = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split('|')
            .reduce((acc, elem) => {
                if (elem) {
                    acc.push(elem.trim());
                }
                return acc;
            }, []);
        if (paramsArray.length === 2) {
            const banTime = parseInt(paramsArray[1], 10);
            if (isNaN(banTime) || banTime <= 0) {
                return null;
            }
            return {
                jsonrpc: '2.0',
                method: 'BanUser',
                params: {
                    nickname: paramsArray[0],
                    roomName: roomName,
                    banTime: paramsArray[1]
                },
                id: 'BanUserId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execUnban = (params: string, roomName: string): cmdResponse => {
    if (params) {
        return {
            jsonrpc: '2.0',
            method: 'UnbanUser',
            params: {
                nickname: params.trim(),
                roomName: roomName
            },
            id: 'UnbanUserId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

const execSticky = (params: string, roomName: string): cmdResponse => {
    if (params) {
        return {
            jsonrpc: '2.0',
            method: 'SendMainMessage',
            params: {
                msg: params.trim(),
                roomName: roomName
            },
            id: 'SendMainMessageId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

const execUnsticky = (params: string, roomName: string): cmdResponse => {
    if (!params) {
        return {
            jsonrpc: '2.0',
            method: 'RemoveMainMessage',
            params: {
                roomName: roomName
            },
            id: 'RemoveMainMessageId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

const execDeleteMsg = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'RemoveMessage',
                params: {
                    roomName: roomName,
                    msgId: paramsArray[0]
                },
                id: 'RemoveMessageId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execBanWord = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'AddBadWordByModerator',
                params: {
                    roomName: roomName,
                    word: paramsArray[0]
                },
                id: 'AddBanWordByModeratorId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execUnbanWord = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'DeleteBadWordByModerator',
                params: {
                    roomName: roomName,
                    word: paramsArray[0]
                },
                id: 'DeleteBanWordByModeratorId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execQuizCreate = (params: string, roomName: string): cmdResponse => {
    if (params) {
        let paramsArray = params.match(/(?:{[^}]*}|^[^}]*$)/g); // Regex pour récupérer les termes entre quote du type : "Une question ?"
        if (paramsArray && paramsArray.length > 3 && paramsArray.length <= 6) {
            paramsArray = paramsArray.map(function (value) {
                return value.substring(1, value.length - 1);
            });

            const [question, correctAnswerIndex, ...responses] = paramsArray;
            if (correctAnswerIndex) {
                const randIndex = Math.floor(Math.random() * responses.length);

                const correctAnswer = responses.splice(
                    parseInt(correctAnswerIndex, 10),
                    1
                )[0];
                responses.splice(randIndex, 0, correctAnswer);
                return {
                    jsonrpc: '2.0',
                    method: 'CreateQuiz',
                    params: {
                        roomName: roomName,
                        question,
                        responses,
                        correctAnswerIndex: randIndex
                    },
                    id: 'CreateQuizId',

                    ns: 'core/chatamax'
                };
            }

            return {
                jsonrpc: '2.0',
                method: 'CreateQuiz',
                params: {
                    roomName: roomName,
                    question,
                    responses
                },
                id: 'CreateQuizId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execQuizList = (params: string, roomName: string): cmdResponse => {
    if (!params) {
        return {
            jsonrpc: '2.0',
            method: 'GetQuizList',
            params: {
                roomName: roomName
            },
            id: 'GetQuizListId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

const execQuizActivate = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'ActivateQuiz',
                params: {
                    roomName: roomName,
                    quizId: paramsArray[0]
                },
                id: 'ActivateQuizId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execQuizDraw = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'DrawQuizWinners',
                params: {
                    roomName: roomName,
                    winnersNb: paramsArray[0]
                },
                id: 'DrawQuizWinnersId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execQuizDelete = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            return {
                jsonrpc: '2.0',
                method: 'DeleteQuiz',
                params: {
                    roomName: roomName,
                    quizId: paramsArray[0]
                },
                id: 'DeleteQuizId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execSlow = (params: string, roomName: string): cmdResponse => {
    if (params) {
        const paramsArray = params
            .trim()
            .split(' ')
            .filter((elem) => elem);
        if (paramsArray.length === 1) {
            const timeInSec = parseInt(paramsArray[0]);
            const timeInMiliSec = timeInSec * 1000;
            return {
                jsonrpc: '2.0',
                method: 'SetSlowMode',
                params: {
                    roomName: roomName,
                    time: timeInMiliSec
                },
                id: 'SetSlowModeId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execQuizEdit = (params: string, roomName: string): cmdResponse => {
    if (params) {
        let paramsArray = params.match(/(?:{[^}]*}|^[^}]*$)/g); // Regex pour récupérer les termes entre quote du type : "Une question ?"
        if (paramsArray && paramsArray.length > 4 && paramsArray.length <= 7) {
            paramsArray = paramsArray.map(function (value) {
                return value.substring(1, value.length - 1);
            });
            const [quizId, question, correctAnswerIndex, ...responses] = paramsArray;

            const indexInt = parseInt(correctAnswerIndex, 10);

            return {
                jsonrpc: '2.0',
                method: 'EditQuiz',
                params: {
                    roomName: roomName,
                    quizId,
                    question,
                    responses,
                    ...(typeof indexInt === 'number' && {
                        correctAnswerIndex: indexInt
                    })
                },
                id: 'EditQuizId',

                ns: 'core/chatamax'
            };
        }
    }
    return null;
};

const execOpen = (params: string, roomName: string): cmdResponse => {
    if (!params) {
        return {
            jsonrpc: '2.0',
            method: 'OpenRoomByModo',
            params: {
                roomName: roomName
            },
            id: 'OpenRoomByModoId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

const execClose = (params: string, roomName: string): cmdResponse => {
    if (!params) {
        return {
            jsonrpc: '2.0',
            method: 'CloseRoomByModo',
            params: {
                roomName: roomName
            },
            id: 'CloseRoomByModoId',

            ns: 'core/chatamax'
        };
    }
    return null;
};

export const chatModoCommands = {
    '/open': execOpen,
    '/close': execClose,
    '/ban': execBan,
    '/to': execTo,
    '/unban': execUnban,
    '/sticky': execSticky,
    '/unsticky': execUnsticky,
    '/delete_msg': execDeleteMsg,
    '/ban_word': execBanWord,
    '/unban_word': execUnbanWord,
    '/quiz_create': execQuizCreate,
    '/quiz_list': execQuizList,
    '/quiz_activate': execQuizActivate,
    '/quiz_draw': execQuizDraw,
    '/quiz_delete': execQuizDelete,
    '/quiz_edit': execQuizEdit,
    '/slow': execSlow
};
