// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import selectFeedProps from '../redux/selectors/selectFeedProps';
import useMergeProps from '../hooks/useMergeProps';
import Feed from '../components/Feed.jsx';
import type { FeedProps } from '../components/Feed.jsx';

type FeedOwnProps = {
    isRoomClosed: boolean,
    bottomFeedRef: any,
    feedRef: any
};

const FeedContainer = (
    ownProps: FeedOwnProps
): React$Element<(_0: FeedProps) => React$Element<any>> => {
    const selectedProps = useSelector(selectFeedProps);

    const enhancedProps = useMergeProps({ ownProps, selectedProps });

    return <Feed {...enhancedProps} />;
};

export default FeedContainer;
