// @flow

import { selectMembers } from '../reducers/roomReducer';

import { createSelector } from 'reselect';

const selectMembersListProps: any = createSelector([selectMembers], (members) => ({
    members
}));

export default selectMembersListProps;
