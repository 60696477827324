// @flow

import React from 'react';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import ChatRootContainer from '../containers/ChatRootContainer';
import themeGenerator from '../style/themeGenerator';
import { configureStore } from '../redux/middlewares';
import Token from '../redux/middlewares/network/token';
import GlobalCSS from '../style/global.css';
import FontLoader from '../style/FontLoader';
import type { OriginApps } from '../types/app';
import { changeLanguageL10n } from '../utils/translator';

const store = configureStore();

export type ChatAppProps = {
    roomName: string,
    applicationName: OriginApps,
    language: string,
    operator: string,
    env: string,
    refreshTokenEndpoint?: string,
    chatWrapperStyle?: Object,
    getToken?: () => Promise<Object>,
    navigate: (action: string) => void
};

export const AppContext: React$Context<any> = React.createContext({
    applicationName: ''
});

export const ChatApp = (props: ChatAppProps): React$Element<any> => {
    const {
        roomName,
        env,
        operator,
        getToken,
        refreshTokenEndpoint,
        applicationName,
        language,
        chatWrapperStyle
    } = props;
    const theme = themeGenerator[applicationName];

    useEffect(() => {
        if (getToken) Token.setGetTokenFunction(getToken);
        if (refreshTokenEndpoint) Token.setRefreshEndpoint(refreshTokenEndpoint);
    }, []);

    useEffect(() => {
        changeLanguageL10n(language);
    }, [language]);

    const appContext = {
        applicationName
    };

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <FontLoader />
                <GlobalCSS />
                <AppContext.Provider value={appContext}>
                    <ChatRootContainer
                        env={env}
                        operator={operator}
                        roomName={roomName}
                        applicationName={applicationName}
                        chatWrapperStyle={chatWrapperStyle}
                    />
                </AppContext.Provider>
            </ThemeProvider>
        </Provider>
    );
};
