// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import { ChatRoot } from '../components/ChatRoot';
import type { ChatRootProps } from '../components/ChatRoot.jsx';
import selectChatRootProps from '../redux/selectors/selectChatRootProps';
import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import { configureApp, startConnecting } from '../redux/actions/chatActions';
import type { OriginApps } from '../types/app';

type ChatRootOwnProps = {
    roomName: string,
    env: string,
    operator: string,
    applicationName: OriginApps,
    chatWrapperStyle?: Object
};

const ChatRootContainer = (
    ownProps: ChatRootOwnProps
): React$Element<(_0: ChatRootProps) => React$Element<any>> => {
    const selectedProps = useSelector(selectChatRootProps);

    const actionsProps = useBinActionsCreator({ startConnecting, configureApp });

    const enhancedProps = useMergeProps({ selectedProps, actionsProps, ownProps });

    return <ChatRoot {...enhancedProps} />;
};

export default ChatRootContainer;
