// @flow

import { createSelector } from 'reselect';
import { UserRoles } from '../../types/user';
import { selectUserRole } from '../reducers/userReducer';
import { selectToggledFeatures } from '../reducers/roomReducer';

const selectMessageComponentProps: any = createSelector(
    [selectUserRole, selectToggledFeatures],
    (userRole, toggledFeatures) => ({
        isModerator: userRole === UserRoles.MODERATOR,
        isDated: toggledFeatures.msgDating
    })
);

export default selectMessageComponentProps;
