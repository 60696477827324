// @flow

import React from 'react';
import InputTextArea from '../components/input_bar_components/InputTextArea';
import useMergeProps from '../hooks/useMergeProps';
import type { InputTextAreaProps } from '../components/input_bar_components/InputTextArea';
import { useSelector } from 'react-redux';
import selectInputTextAreaProps from '../redux/selectors/selectInputTextAreaProps';

type InputTxtAreaOwnProps = {
    handleKeyDown: (e: any) => void,
    handleChange: (e: any) => void,
    value: string,
    placeholder: string,
    isModerator: boolean,
    disabled: boolean,
    inputRef: any
};

const InputTextAreaContainer = (
    ownProps: InputTxtAreaOwnProps
): React$Element<(_0: InputTextAreaProps) => React$Element<any>> => {
    const selectedProps = useSelector(selectInputTextAreaProps);
    const enhancedProps = useMergeProps({ ownProps, selectedProps });

    return <InputTextArea {...enhancedProps} />;
};

export default InputTextAreaContainer;
