// @flow

import React, { useEffect, useRef, useState } from 'react';
import ButtonCmdAction from '../common/ButtonCmdAction';
import styled from 'styled-components';
import { Emoji } from 'assets/images';
import Picker from 'emoji-picker-react';

const EmojiButton = styled(ButtonCmdAction)`
    height: 20px;
    background-color: ${({ theme }) => theme.color.inputBackground};
`;

const EmojiWrapper = styled.div`
    position: relative;
`;

type EmojiPickerProps = {
    handleChange: (event: any, emoji: string) => void,
    disabled: boolean
};

const EmojiPicker = ({
    handleChange,
    disabled
}: EmojiPickerProps): React$Element<any> => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            const node = ref.current;
            if (isOpen && node && !node.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isOpen]);

    const handleOnClick = () => {
        setIsOpen((state) => !state);
    };

    const onEmojiClick = (emojiObject, event) => {
        handleChange(null, emojiObject.emoji);
        setIsOpen(false);
    };

    return (
        <EmojiWrapper ref={ref}>
            <EmojiButton
                commandFunc={handleOnClick}
                Icon={Emoji}
                alt="EmojiIcon"
                disabled={disabled}
            />
            {isOpen ? (
                <Picker
                    theme={'dark'}
                    onEmojiClick={onEmojiClick}
                    searchDisabled={true}
                    previewConfig={{ showPreview: false }}
                    width={270}
                    height={320}
                    lazyLoadEmojis={true}
                />
            ) : null}
        </EmojiWrapper>
    );
};

export default EmojiPicker;
