// @flow

const black = {
    black: '#000000',
    chineseblack: '#0f1118',
    darkjungleblack: '#1a1c24',
    gunmetalblack: '#2a2d37',
    darkgunmetalblack: '#21232D',
    eerieBlack: '#1C1D21'
};

const grey = {
    darkouterspaceGrey: '#31333a',
    outerspaceGrey: '#43485a',
    electicGrey: '#5d617b',
    onyx: '#333543'
};

const yellow = {
    mikadoyellow: '#ffc514',
    blondyellow: '#ffefb7'
};

const green = {
    oceangreen: '#46cc8a',
    slimygreen: '#2BA400'
};

const red = {
    red: '#ff0000',
    winamaxRed: '#D32F27',
    errorRed: '#FF494C',
    persianRed: '#651D1E',
    darkSienna: '#361010'
};

const blue = {
    blue: '#0000ff',
    infoBlue: '#4C49FF',
    indigo: '#1E1D65',
    cetaceanBlue: '#101036'
};

const colors = {
    transparent: 'transparent',
    white: '#ffffff',
    ...black,
    ...grey,
    ...yellow,
    ...green,
    ...red,
    ...blue
};

export default colors;
