// @flow

import * as actions from '../../actions/chatActions';
import type { Quiz, QuizFromApi, QuizStatus } from '../../../types/quiz';
import type { UserRoleType } from '../../../types/user';
import type { Message } from '../../../types/message';
import { quizStatus, toQuiz } from '../../../types/quiz';
import { AlertTypes, createInputAlert } from '../../../types/message';
import type { ErrorCodesType } from '../../../types/jsonrpc';
import { ErrorCodes } from '../../../types/jsonrpc';
import type {
    FeaturesFromApi,
    MemberInfo,
    RoomStatusType,
    RoomType
} from '../../../types/room';
import { RoomTypes } from '../../../types/room';
import { UserRoles } from '../../../types/user';
import { __ } from '../../../utils/translator';
import { getBannedMessage } from '../../../utils/errors';
import { getDrawMessage } from '../../../utils/infos';

type ApiError = {
    code: ErrorCodesType,
    reason: string,
    reason_message: ?string
};

type JoinRoomResult = {
    lastMessages: Array<Message>,
    type: RoomType,
    features: FeaturesFromApi,
    userRole: UserRoleType,
    mainMessage: string,
    quiz: ?QuizFromApi,
    slowModeTime: number,
    status: RoomStatusType,
    members: Array<MemberInfo>
};

const handleJoinRoom = (
    store: any,
    result: JoinRoomResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.FORBIDDEN) {
            if (error?.reason === 'private_room') {
                store.dispatch(actions.setType(RoomTypes.PRIVATE));
                store.dispatch(actions.changeUserRole(UserRoles.NOT_ALLOWED));
            }
        }
    } else {
        const {
            lastMessages,
            type,
            features,
            userRole,
            mainMessage,
            quiz,
            slowModeTime,
            status,
            members
        } = result;
        const messageList: Array<Message> = lastMessages.sort((m1, m2) =>
            Math.sign(m2.timestamp - m1.timestamp)
        );
        const quizModel = quiz ? toQuiz(quiz, true) : null;
        const timeInSec = slowModeTime / 1000;
        store.dispatch(
            actions.receiveJoinInfo(
                messageList,
                userRole,
                mainMessage,
                quizModel,
                timeInSec,
                status,
                type,
                members,
                features
            )
        );
    }
};

type GetQuizListResult = {
    currentQuiz: Quiz,
    quizList: Array<Quiz>
};

const handleGetQuizList = (
    store: any,
    result: GetQuizListResult & { error: ?ApiError }
) => {
    const { currentQuiz, quizList } = result ?? {};
    if (quizList) {
        const newQuizList = quizList.map((quiz) => ({
            ...quiz,
            isCurrentQuiz: false
        }));
        store.dispatch(actions.storeQuizList(newQuizList));
    }
    if (currentQuiz) {
        const newCurrentQuiz = { ...currentQuiz, isCurrentQuiz: true };
        store.dispatch(actions.setQuiz(newCurrentQuiz));
    }
};

type ActivateQuizResult = {};

const handleActivateQuiz = (
    store: any,
    result: ActivateQuizResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.ALREADY_EXISTS) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('Quiz_in_progress'))
                )
            );
        }
    } else {
        store.dispatch(actions.execCommand('/quiz_list', ''));
    }
};

type AnswerQuizResult = {};

const handleAnswerQuiz = (
    store: any,
    result: AnswerQuizResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.ALREADY_EXISTS) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('Quiz_answered'))
                )
            );
        }
    } else {
        store.dispatch(actions.changeQuizStatus(quizStatus.ANSWERED));
    }
};

type DrawQuizWinnersResult = {
    winners: Array<string>,
    correctAnswer: string
};

const handleDrawQuizWinners = (
    store: any,
    result: DrawQuizWinnersResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.RESOURCE_NOT_FOUND) {
            if (error?.reason === 'winners') {
                store.dispatch(
                    actions.raiseInputAlert(
                        createInputAlert(AlertTypes.ERROR, __('No_winners'))
                    )
                );
            }
        }
        if (error?.code === ErrorCodes.FORBIDDEN) {
            if (error?.reason === 'quiz_correct_answer') {
                store.dispatch(
                    actions.raiseInputAlert(
                        createInputAlert(AlertTypes.ERROR, __('No_answer_setted'))
                    )
                );
            }
        }
    } else {
        const quizResultString = getDrawMessage(
            result.winners,
            result.correctAnswer
        );
        store.dispatch(actions.setPreparedInput(quizResultString));
    }
};

type BanUserResult = {};

const handleBanUser = (store: any, result: BanUserResult & { error: ?ApiError }) => {
    const { error } = result;
    if (!error) {
        store.dispatch(
            actions.raiseInputAlert(
                createInputAlert(AlertTypes.INFO, __('User_banned'))
            )
        );
    }
};

type UnbanUserResult = {};

const handleUnbanUser = (
    store: any,
    result: UnbanUserResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.PRECONDITION_FAILED) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('User_not_banned'))
                )
            );
        }
    } else {
        store.dispatch(
            actions.raiseInputAlert(
                createInputAlert(AlertTypes.INFO, __('User_unbanned'))
            )
        );
    }
};

type BanWordResult = {};

const handleBanWord = (store: any, result: BanWordResult & { error: ?ApiError }) => {
    const { error } = result;
    if (!error) {
        store.dispatch(
            actions.raiseInputAlert(
                createInputAlert(AlertTypes.INFO, __('Word_banned'))
            )
        );
    }
};

const handleUnbanWord = (
    store: any,
    result: BanWordResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (!error) {
        store.dispatch(
            actions.raiseInputAlert(
                createInputAlert(AlertTypes.INFO, __('Word_unbanned'))
            )
        );
    }
};

type SlowModeResult = {};

const handleSlowMode = (
    store: any,
    result: SlowModeResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.FORBIDDEN) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('Feature_not_toggled'))
                )
            );
        }
    } else {
        store.dispatch(
            actions.raiseInputAlert(
                createInputAlert(AlertTypes.INFO, __('Slow_mode_changed'))
            )
        );
    }
};

type DeleteQuizResult = {};

const handleDeleteQuiz = (
    store: any,
    result: DeleteQuizResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (!error) {
        store.dispatch(actions.execCommand('/quiz_list', ''));
    }
};

type CreateQuizResult = {
    quizId: string,
    question: string,
    responses: Array<string>,
    status: QuizStatus
};

const handleCreateQuiz = (
    store: any,
    result: CreateQuizResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.FORBIDDEN) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('Feature_not_toggled'))
                )
            );
        }
    } else {
        store.dispatch(actions.execCommand('/quiz_list', ''));
    }
};

type EditQuizResult = {
    quizId: string,
    question: string,
    responses: Array<string>,
    status: QuizStatus
};

const handleEditQuiz = (
    store: any,
    result: EditQuizResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (!error) {
        store.dispatch(actions.execCommand('/quiz_list', ''));
    }
};

type SendMessageResult = {};

type SendMessageForbiddenError = {
    reason: string,
    ban_reason: string,
    ban_time: number,
    ban_reason_msg: string
};

const handleSendMessage = (
    store: any,
    result: SendMessageResult & { error: ?(ApiError & SendMessageForbiddenError) }
) => {
    const { error } = result;
    if (error) {
        const { code, reason, ban_reason, ban_time, ban_reason_msg } = error ?? {};
        if (code === ErrorCodes.FORBIDDEN) {
            if (reason === 'ban') {
                store.dispatch(
                    actions.raiseInputAlert(
                        createInputAlert(
                            AlertTypes.ERROR,
                            getBannedMessage(ban_reason, ban_time, ban_reason_msg)
                        )
                    )
                );
            }
        }
    }
};

type SendMainMessageResult = {};

const handleSendMainMessage = (
    store: any,
    result: SendMainMessageResult & { error: ?ApiError }
) => {
    const { error } = result;
    if (error) {
        if (error?.code === ErrorCodes.FORBIDDEN) {
            store.dispatch(
                actions.raiseInputAlert(
                    createInputAlert(AlertTypes.ERROR, __('Feature_not_toggled'))
                )
            );
        }
    }
};

export const proxyResponses = {
    ActivateQuiz: handleActivateQuiz,
    AddBadWordByModerator: handleBanWord,
    Answer: handleAnswerQuiz,
    BanUser: handleBanUser,
    CreateQuiz: handleCreateQuiz,
    DeleteBadWordByModerator: handleUnbanWord,
    DeleteQuiz: handleDeleteQuiz,
    DrawQuizWinners: handleDrawQuizWinners,
    EditQuiz: handleEditQuiz,
    GetQuizList: handleGetQuizList,
    JoinRoom: handleJoinRoom,
    SetSlowMode: handleSlowMode,
    SendMainMessage: handleSendMainMessage,
    SendMessage: handleSendMessage,
    UnbanUser: handleUnbanUser
};
