// @flow

import React from 'react';
import styled from 'styled-components';
import type { Quiz } from '../../types/quiz';
import ButtonCmdAction from '../common/ButtonCmdAction.jsx';
import { AddIcon, Cross } from 'assets/images';
import { PopupHeaderWrapper, StyledButton } from '../common/sharedStyledComponent';
import QuizListElementContainer from '../../containers/QuizListElementContainer.jsx';
import { __ } from '../../utils/translator';

const QuizListPopupWrapper = styled.div`
    min-width: 480px;
    min-height: 350px;
`;

const AddButtonImg = styled(AddIcon)`
    height: 18px;
    margin-right: 10px;
    margin-left: 10px;
`;

const AddButtonText = styled.div`
    text-align: left;
    color: ${({ theme }) => theme.color.secondText};
`;

const AddButton = styled(StyledButton)`
    width: 174px;
    background-color: ${({ theme }) => theme.color.firstButton};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
`;

const CloseButton = styled(ButtonCmdAction)`
    height: 15px;
    margin: 15px 10px 0 0;
`;

export type QuizListPopupProps = {
    closePopup: () => void,
    quizList: Array<Quiz>,
    currentQuiz: Quiz,
    changePopup: (quizToEdit: ?Quiz) => void
};

const QuizListPopup = ({
    closePopup,
    quizList,
    currentQuiz,
    changePopup
}: QuizListPopupProps): React$Element<any> => {
    return (
        <QuizListPopupWrapper>
            <PopupHeaderWrapper>
                <AddButton onClick={() => changePopup(null)}>
                    <AddButtonImg alt="AddQuizIcon" />
                    <AddButtonText>{__('Create_quiz')}</AddButtonText>
                </AddButton>
                <CloseButton
                    Icon={Cross}
                    alt="QuizManagementCross"
                    commandFunc={closePopup}
                />
            </PopupHeaderWrapper>
            <div>
                {currentQuiz ? (
                    <QuizListElementContainer
                        quiz={currentQuiz}
                        key={currentQuiz.id}
                        changePopup={changePopup}
                    />
                ) : null}
                <div>
                    {quizList.map((quiz, index) => (
                        <QuizListElementContainer
                            key={quiz.id}
                            index={index}
                            quiz={quiz}
                            changePopup={changePopup}
                        />
                    ))}
                </div>
            </div>
        </QuizListPopupWrapper>
    );
};

export default QuizListPopup;
