// @flow

import type { UserRoleType, UserStatusType } from './user';

export const RoomStatus = Object.freeze({
    OPEN: 'OPEN',
    CLOSED: 'CLOSED'
});

export type RoomStatusType = $Values<typeof RoomStatus>;

export const RoomTypes = Object.freeze({
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE'
});

export type RoomType = $Values<typeof RoomTypes>;

export type FeaturesFromApi = {
    quiz: boolean,
    mainMessage: boolean,
    slowMode: boolean
};

export type RightWidgetsFeat = {
    listMembers: boolean
};

export type FeaturesToggle = {
    topWidgets: {
        quiz: boolean,
        listMembers: boolean,
        mainMessage: { img: boolean, changeText: boolean }
    },
    rightWidgets: RightWidgetsFeat,
    msgDating: boolean,
    roomStatus: boolean,
    slowMode: boolean
};

export type MemberInfo = {
    nickname: string,
    role: UserRoleType,
    status: UserStatusType
};
