// @flow

import React, { useCallback, useContext } from 'react';
import ButtonCmdAction from '../common/ButtonCmdAction';
import styled from 'styled-components';
import { Cross, Users } from 'assets/images';
import { AppContext } from '../ChatApp.jsx';
import { MainMsgLogo } from '../../assets/images';
import { __ } from '../../utils/translator';
import { RightWidgetsContext } from '../ChatRoot.jsx';
import { frame } from '../../style/screenSize';
import ConditionalWrapper from '../common/ConditionalWrapper';

const MainMessageWrapper = styled.div`
    height: auto;
    width: 100%;
    background-color: ${({ theme }) => theme.color.secondBackground};
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainMessageText = styled.div`
    text-align: center;
    margin: auto;
    font-size: ${({ theme }) => theme.font.mainMessage.size};
    color: ${({ theme }) => theme.color.mainText};
    padding: 15px 0px 15px 0px;
    font-weight: ${({ theme }) => theme.font.mainMessage.weight};
`;

const MainMessageTextOverlay = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ButtonWrapper = styled(MainMessageTextOverlay)`
    padding-right: 20px;
`;

const ImgWrapper = styled(MainMessageTextOverlay)``;

const UnstickyButton = styled(ButtonCmdAction)`
    height: 15px;
    margin: 10px 10px 0 0;
`;

const OpenRightWidgetsButton = styled(ButtonCmdAction)`
    height: 0;
    width: 0;

    @media ${frame.mobileM} {
        height: 15px;
    }
`;

const WidgetsLogo = {
    listMembers: Users
};

export type MainMessageComponentProps = {
    mainMessage: string,
    execCommand: (cmd: string, params: string) => void,
    isModerator: boolean,
    isRoomClosed: boolean,
    hasImg: boolean,
    txtRemovable: boolean
};

const MainMessage = ({
    mainMessage,
    execCommand,
    isModerator,
    isRoomClosed,
    hasImg,
    txtRemovable
}: MainMessageComponentProps): React$Element<any> => {
    const { applicationName } = useContext(AppContext);
    const { hasRightWidgets, isWidgetsOpen, setIsWidgetsOpen, widgetsToggled } =
        useContext(RightWidgetsContext);

    const widgetIcon =
        WidgetsLogo[
            Object.keys(widgetsToggled).find(
                (widgetLabel) => widgetsToggled[widgetLabel]
            ) || 'listMembers'
        ];

    const execUnstickyCallback = useCallback(
        () => execCommand('/unsticky', ''),
        [execCommand]
    );

    const openWidgetsCb = useCallback(
        () => setIsWidgetsOpen(true),
        [setIsWidgetsOpen]
    );

    let MainMsgLogoImg = null;
    if (applicationName) {
        MainMsgLogoImg = MainMsgLogo[applicationName];
    }

    return (
        <MainMessageWrapper>
            {hasImg && MainMsgLogoImg ? (
                <ImgWrapper>
                    <MainMsgLogoImg height="40px" style={{ padding: '10px' }} />
                </ImgWrapper>
            ) : null}
            <MainMessageText>
                {isRoomClosed ? __('Room_closed') : mainMessage}
            </MainMessageText>
            <ConditionalWrapper
                condition={
                    (txtRemovable && isModerator) ||
                    (hasRightWidgets && !isWidgetsOpen)
                }
                wrapper={(children) => <ButtonWrapper>{children}</ButtonWrapper>}>
                {txtRemovable && isModerator ? (
                    <UnstickyButton
                        Icon={Cross}
                        alt="MainMessageCross"
                        commandFunc={execUnstickyCallback}
                    />
                ) : null}
                {hasRightWidgets && !isWidgetsOpen ? (
                    <OpenRightWidgetsButton
                        Icon={widgetIcon}
                        alt="OpenRightWidgetsBtn"
                        commandFunc={openWidgetsCb}
                    />
                ) : null}
            </ConditionalWrapper>
        </MainMessageWrapper>
    );
};

export default MainMessage;
