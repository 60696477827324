// @flow
import type { AlertType } from '../../types/message';

import { AlertTypes } from '../../types/message';

import React from 'react';
import styled from 'styled-components';

const AlertWrapper = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 10px;
    padding: 0.5em 0.6em;
    color: ${({ theme, $isError }) =>
        $isError ? theme.color.alertErrorText : theme.color.alertInfoText};
    background: ${({ theme, $isError }) =>
        $isError
            ? `linear-gradient(to right, ${theme.color.alertErrorLGrad}, ${theme.color.alertErrorDGrad})`
            : `linear-gradient(to right, ${theme.color.alertInfoLGrad}, ${theme.color.alertInfoDGrad})`};
`;

const AlertIcon = styled.div`
    background: ${({ theme, $isError }) =>
        $isError ? theme.color.alertErrorText : theme.color.alertInfoText};
    color: rgb(0, 0, 0);
    font-size: 1.1em;
    font-weight: bold;
    width: 1.4em;
    height: 1.4em;
    border-radius: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 1em;
`;

type AlertMessageProps = {
    className?: string,
    text: string,
    type: AlertType
};

const AlertMessage = ({
    text,
    type,
    className = ''
}: AlertMessageProps): React$Element<any> => {
    const isError = type === AlertTypes.ERROR;
    return (
        <AlertWrapper className={className} $isError={isError}>
            <AlertIcon $isError={isError}>{isError ? '!' : 'i'}</AlertIcon>
            {text}
        </AlertWrapper>
    );
};

export default AlertMessage;
