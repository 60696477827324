// @flow

export const UserRoles = Object.freeze({
    MODERATOR: 'moderator',
    READER: 'reader',
    WRITER: 'writer',
    LEADER: 'leader',
    NOT_ALLOWED: ''
});

export type UserRoleType = $Values<typeof UserRoles>;

export const UserStatus = Object.freeze({
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED'
});

export type UserStatusType = $Values<typeof UserStatus>;
