// @flow

import * as actionTypes from '../../types/actions';
import type { ChatAppState } from '../../types/states';
import type { ActionType } from '../../types/actions';
import { createSelector } from 'reselect';

export const initialChatState: ChatAppState = {
    isConnected: false,
    isEstablishingConnection: false,
    inputAlert: null,
    preparedInputValue: '',
    env: 'dev',
    operator: 'wam'
};

export function chatAppReducer(
    state: ChatAppState = initialChatState,
    action: ActionType
): ChatAppState {
    switch (action.type) {
        case actionTypes.START_CONNECTING:
            return {
                ...state,
                isEstablishingConnection: true
            };
        case actionTypes.CONNECTION_ESTABLISHED:
            return {
                ...state,
                isEstablishingConnection: true,
                isConnected: true
            };
        case actionTypes.CONNECTION_FAILED:
            return {
                ...state,
                isEstablishingConnection: false,
                isConnected: false
            };
        case actionTypes.CONFIGURE_APP:
            return {
                ...state,
                env: action.payload.env,
                operator: action.payload.operator
            };
        case actionTypes.RAISE_INPUT_ALERT:
            return {
                ...state,
                inputAlert: action.payload.inputAlert
            };
        case actionTypes.SET_PREPARED_INPUT:
            return {
                ...state,
                preparedInputValue: action.payload.value
            };
        default:
            return state;
    }
}

export const selectIsConnected: any = createSelector(
    [(state) => state.chatApp.isConnected],
    (isConnected) => isConnected
);

export const selectIsEstablishingConnection: any = createSelector(
    [(state) => state.chatApp.isEstablishingConnection],
    (isEstablishingConnection) => isEstablishingConnection
);

export const selectInputAlert: any = createSelector(
    [(state) => state.chatApp.inputAlert],
    (inputAlert) => inputAlert
);

export const selectPreparedInputValue: any = createSelector(
    [(state) => state.chatApp.preparedInputValue],
    (preparedInputValue) => preparedInputValue
);
