import styled from 'styled-components';

export const VerticalSpacer = styled.div`
    height: 5px;
`;

export const PopupHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.color.thirdLayerBg};
`;

export const PopupHeaderText = styled.div`
    font-size: ${({ theme }) => theme.font.popupHeader.size};
    font-weight: ${({ theme }) => theme.font.popupHeader.weight};
    text-align: left;
    color: ${({ theme }) => theme.color.mainText};
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 0 15px 0 15px;
`;

export const Label = styled.label`
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
`;

export const LabelText = styled.div`
    color: ${({ theme }) => theme.color.mainText};
    margin-bottom: 3px;
    font-weight: ${({ theme }) => theme.font.formLabel.weight};
    font-size: ${({ theme }) => theme.font.formLabel.size};
    text-align: left;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CenteredRow = styled(Row)`
    align-items: center;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledButton = styled.button`
    height: 40px;
    border-radius: 6px;
    border: 0px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: ${({ theme }) => theme.font.generalButton.weight};
    font-size: ${({ theme }) => theme.font.generalButton.size};
`;
