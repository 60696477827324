export default {
  "l10n": {
    "currency": '\u20ac',
    "date": {
      "months": {
        "full": ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],
        "abbr": ["ene.","feb.","mar.","abr.","may.","jun.","jul.","ago.","sep.","oct.","nov.","dic."]
      },
      "days": {
        "full": ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"],
        "abbr": ["do.","lu.","ma.","mi.","ju.","vi.","sá."]
      },
      "patterns": {
        "longDate": "dddd, D MMMM YYYY",
        "dateSlash": "DD/MM/YYYY",
        "dateShort": "dddd D MMMM",
        "dateShortWithDay": "Do MMMM YYYY",
        "dateMedium": "dddd, D [de] MMMM - HH[:]mm [h]",
        "dateMediumReversed" : "H[:]mm - D MMMM YYYY",
        "dateMediumSlash": "DD[/]MM[/]YYYY [a las] H[:]mm",
        "dateLarge": "dddd, D [de] MMMM [a las] HH[:]mm",
        "timeShort": "HH:mm",
        "timeMedium": "DD[/]MM HH:mm"
      }
    },
    "number": {
      "patterns": {
        "standard": ["-n", "n"],
        "amount": ["-n S", "n S"]
      },
      "group": {
        "separator": ".",
        "length": 3,
        "abbr": ["", "k", "M", "B"]
      },
      "decimal": {
        "separator": ",",
        "length": 2
      },
      "plural": "nplurals=2; plural=(n != 1);",
      "ordinal": function(number) { return number + (number === 1 ? '°' : '°'); }
    }
  },
  "translations": {
    "messages": {
  "Answer": [
    null,
    "Respuesta"
  ],
  "Auth_fail": [
    null,
    "No hemos conseguido realizar la autentificación. Por favor, recarga la página"
  ],
  "Close_room_question": [
    null,
    "¿Estás seguro de que quieres cerrar el chat?"
  ],
  "Cmd_not_exists": [
    null,
    "El comando no existe"
  ],
  "Cmd_syntax": [
    null,
    "Sintaxis no respetada en el comando"
  ],
  "Congrats %1$s ! Right_answer_was %2$s": [
    null,
    "¡Felicidades, %1$s! La respuesta correcta era %2$s"
  ],
  "Connect_to_send_msg": [
    null,
    "Conéctate para enviar un mensaje"
  ],
  "Create": [
    null,
    "Crear"
  ],
  "Create_quiz": [
    null,
    "Crear un concurso"
  ],
  "Definitively": [
    null,
    "Definitivamente"
  ],
  "Edit": [
    null,
    "Editar"
  ],
  "Edit_quiz": [
    null,
    "Editar respuesta"
  ],
  "Feature_not_toggled": [
    null,
    "Esta función no está activada en el chat"
  ],
  "Flooding": [
    null,
    "Flooding"
  ],
  "For_the_reason": [
    null,
    "Por la siguiente razón:"
  ],
  "Moderator": [
    null,
    "Moderador"
  ],
  "No": [
    null,
    "No"
  ],
  "No_answer_setted": [
    null,
    "Todavía no se ha introducido la respuesta correcta, edita el concurso"
  ],
  "No_right_answer": [
    null,
    "No designar la respuesta correcta"
  ],
  "No_winners": [
    null,
    "Todavía no hay ganadores. Inténtalo más tarde"
  ],
  "Not_authorized_banned %1$s Because_of %2$s": [
    null,
    "Acción no autorizada ya que estás baneado %1$s por %2$s"
  ],
  "Optional": [
    null,
    "Opcional"
  ],
  "Question": [
    null,
    "Pregunta"
  ],
  "Quiz": [
    null,
    "Concurso"
  ],
  "Quiz_answer_registered": [
    null,
    "Tu respuesta se ha registrado"
  ],
  "Quiz_answered": [
    null,
    "Ya has respondido al concurso"
  ],
  "Quiz_in_progress": [
    null,
    "Ya hay un concurso en juego"
  ],
  "Required_field": [
    null,
    "No has completado todos los campos "
  ],
  "Required_rights": [
    null,
    "No tienes los derechos necesarios para realizar esta acción"
  ],
  "Room_closed": [
    null,
    "El chat está cerrado en este momento"
  ],
  "Room_not_allowed": [
    null,
    "No puedes unirte a este chat"
  ],
  "Slow_mode_changed": [
    null,
    "El slow-mode ha sido cambiado"
  ],
  "Today": [
    null,
    "Hoy"
  ],
  "User_banned": [
    null,
    "El usuario ha sido baneado"
  ],
  "User_not_banned": [
    null,
    "El usuario no está baneado"
  ],
  "User_unbanned": [
    null,
    "El usuario ha sido desbaneado"
  ],
  "Word_banned": [
    null,
    "Esta palabra ha sido baneada"
  ],
  "Word_unbanned": [
    null,
    "Esta palabra ha sido desbaneada"
  ],
  "Write_banner": [
    null,
    "Escribir un banner"
  ],
  "Write_message": [
    null,
    "Escribir mensaje"
  ],
  "Yes": [
    null,
    "Sí"
  ],
  "Yesterday": [
    null,
    "Ayer"
  ]
}
  }
}
