// @flow

import { selectCurrentQuiz, selectQuizList } from '../reducers/quizReducer';

import { createSelector } from 'reselect';

const selectQuizListPopupProps: any = createSelector(
    [selectQuizList, selectCurrentQuiz],
    (modoQuizList, currentQuiz) => ({
        quizList: modoQuizList,
        currentQuiz
    })
);

export default selectQuizListPopupProps;
