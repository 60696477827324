// @flow

export const quizStatus = Object.freeze({
    CREATED: 'Created',
    OPEN: 'Open',
    ANSWERED: 'Answered'
});

export type QuizStatus = $Values<typeof quizStatus>;

export type QuizFromApi = {
    id: string,
    question: string,
    responses: Array<string>,
    hasBeenAnswered: boolean
};

export type Quiz = {
    id: string,
    question: string,
    responses: Array<string>,
    correctAnswerIndex?: number,
    status: QuizStatus,
    isCurrentQuiz: boolean
};

export const toQuiz = (quizFromApi: QuizFromApi, isCurrentQuiz: boolean): Quiz => {
    const { id, question, responses, hasBeenAnswered } = quizFromApi;
    return {
        id,
        question,
        responses,
        status: hasBeenAnswered ? quizStatus.ANSWERED : quizStatus.OPEN,
        isCurrentQuiz
    };
};
