export default {
  "l10n": {
    "currency": '\u20ac',
    "date": {
      "months": {
        "full": ["janvier","février","mars","avril","mai","juin","juillet","août","septembre","octobre","novembre","décembre"],
        "abbr": ["janv.","févr.","mars","avr.","mai","juin","juil.","août","sept.","oct.","nov.","déc."]
      },
      "days": {
        "full": ["dimanche","lundi","mardi","mercredi","jeudi","vendredi","samedi"],
        "abbr": ["dim.","lun.","mar.","mer.","jeu.","ven.","sam."]
      },
      "patterns": {
        "longDate": "dddd D MMMM YYYY",
        "dateSlash": "DD/MM/YYYY",
        "dateShort": "dddd D MMMM",
        "dateShortWithDay": "Do MMMM YYYY",
        "dateMedium": "dddd D MMMM - H[h]mm",
        "dateMediumReversed" : "H[h]mm - D MMMM YYYY",
        "dateMediumSlash": "DD[/]MM[/]YYYY [à] H[h]mm",
        "dateLarge": "D MMM YYYY [à] HH[:]mm",
        "timeShort": "LT",
        "timeMedium": "DD[/]MM LT"
      }
    },
    "number": {
      "patterns": {
        "standard": ["-n", "n"],
        "amount": ["-n S", "n S"]
      },
      "group": {
        "separator": " ",
        "length": 3,
        "abbr": ["", "k", "M", "Md"]
      },
      "decimal": {
        "separator": ",",
        "length": 2
      },
      "ordinal": function (number) { return number + (number === 1 ? 'er' : 'e'); }
    }
  },
  "translations": {
    messages: {
  "Answer": [
    null,
    "Réponse"
  ],
  "Auth_fail": [
    null,
    "Nous n'avons pas réussi à vous authentifier au chat, veuillez recharger la page"
  ],
  "Close_room_question": [
    null,
    "Êtes-vous sûr de vouloir fermer le chat ?"
  ],
  "Cmd_not_exists": [
    null,
    "La commande n'existe pas"
  ],
  "Cmd_syntax": [
    null,
    "La syntaxe n'est pas respectée pour la commande : "
  ],
  "Congrats %1$s ! Right_answer_was %2$s": [
    null,
    "Bravo à %1$s ! La bonne réponse était %2$s"
  ],
  "Connect_to_send_msg": [
    null,
    "Connectez-vous pour envoyer un message s'il vous plait"
  ],
  "Create": [
    null,
    "Créer"
  ],
  "Create_quiz": [
    null,
    "Créer un quiz"
  ],
  "Definitively": [
    null,
    "définitivement"
  ],
  "Edit": [
    null,
    "Éditer"
  ],
  "Edit_quiz": [
    null,
    "Éditer le quiz"
  ],
  "Feature_not_toggled": [
    null,
    "Cette fonctionnalité n'est pas activée dans ce chat"
  ],
  "Flooding": [
    null,
    "flooding"
  ],
  "For_the_reason": [
    null,
    "pour la raison suivante"
  ],
  "Moderator": [
    null,
    "un modérateur"
  ],
  "No": [
    null,
    "Non"
  ],
  "No_answer_setted": [
    null,
    "La bonne réponse n'a pas été indiquée, veuillez éditer le quiz"
  ],
  "No_right_answer": [
    null,
    "Ne pas désigner la bonne réponse :"
  ],
  "No_winners": [
    null,
    "Il n'y a pas de gagnant(s) encore. Réessayez plus tard"
  ],
  "Not_authorized_banned %1$s Because_of %2$s": [
    null,
    "Action non autorisée, vous êtes banni %1$s suite à %2$s"
  ],
  "Optional": [
    null,
    "optionnel"
  ],
  "Question": [
    null,
    "Question"
  ],
  "Quiz": [
    null,
    "Quiz"
  ],
  "Quiz_answer_registered": [
    null,
    "Votre réponse a été prise en compte ! Merci !"
  ],
  "Quiz_answered": [
    null,
    "Vous avez déjà répondu au quiz"
  ],
  "Quiz_in_progress": [
    null,
    "Il y a déjà un quiz en cours"
  ],
  "Required_field": [
    null,
    "Champs requis manquants !"
  ],
  "Required_rights": [
    null,
    "Vous n'avez pas les droits requis pour cette action"
  ],
  "Room_closed": [
    null,
    "Le chat est fermé pour le moment"
  ],
  "Room_not_allowed": [
    null,
    "Vous ne pouvez pas rejoindre ce chat"
  ],
  "Slow_mode_changed": [
    null,
    "Le slow-mode a été changé"
  ],
  "Today": [
    null,
    "Aujourd'hui"
  ],
  "User_banned": [
    null,
    "L'utilisateur a bien été banni"
  ],
  "User_not_banned": [
    null,
    "L'utilisateur n'est pas banni"
  ],
  "User_unbanned": [
    null,
    "L'utilisateur a bien été débanni"
  ],
  "Word_banned": [
    null,
    "Le mot a bien été banni"
  ],
  "Word_unbanned": [
    null,
    "Le mot a bien été débanni"
  ],
  "Write_banner": [
    null,
    "Saisir une bannière"
  ],
  "Write_message": [
    null,
    "Écrivez un message"
  ],
  "Yes": [
    null,
    "Oui"
  ],
  "Yesterday": [
    null,
    "Hier"
  ]
}
  }
}
