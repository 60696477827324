import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    #root {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
    }

    .EmojiPickerReact {
        position: absolute !important; 
        bottom: 110%; 
        right: 0px;
    }
`;
