// @flow

export type jsonrpcRequest = {
    ns: string,
    method: string,
    params: Object,
    id: string,
    jsonsrpc?: string
};

export const ErrorCodes = Object.freeze({
    ALREADY_EXISTS: -30409,
    RESOURCE_NOT_FOUND: -30404,
    FORBIDDEN: -30403,
    PRECONDITION_FAILED: -30412
});

export type ErrorCodesType = $Values<typeof ErrorCodes>;
