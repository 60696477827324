// @flow

import { createSelector } from 'reselect';
import { UserRoles } from '../../types/user';
import { selectUserRole } from '../reducers/userReducer';
import { selectInputAlert, selectPreparedInputValue } from '../reducers/chatReducer';
import {
    selectSlowModeTime,
    selectStatus,
    selectToggledFeatures
} from '../reducers/roomReducer';

const selectInputBarProps: any = createSelector(
    [
        selectUserRole,
        selectInputAlert,
        selectSlowModeTime,
        selectStatus,
        selectPreparedInputValue,
        selectToggledFeatures
    ],
    (
        userRole,
        inputAlert,
        slowModeTime,
        status,
        preparedInputValue,
        toggledFeatures
    ) => ({
        msgInfo: inputAlert,
        slowModeTime,
        isModerator: userRole === UserRoles.MODERATOR,
        roomStatus: status,
        preparedInputValue,
        toggledFeatures
    })
);

export default selectInputBarProps;
