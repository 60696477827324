// @flow

type AppParams = {
    msgLimit: number
};

const config: AppParams = {
    msgLimit: 150
};

export default config;
