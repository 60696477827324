// @flow

import * as actionTypes from '../../types/actions';
import type { QuizState } from '../../types/states';
import type { ActionType } from '../../types/actions';
import { createSelector } from 'reselect';

export const initialQuizState: QuizState = {
    currentQuiz: null,
    modoQuizList: []
};

export function quizReducer(
    state: QuizState = initialQuizState,
    action: ActionType
): QuizState {
    switch (action.type) {
        case actionTypes.STORE_QUIZ_LIST:
            return {
                ...state,
                modoQuizList: action.payload.quizList
            };
        case actionTypes.RECEIVE_JOIN_INFO:
            return {
                ...state,
                currentQuiz: action.payload.quiz
            };
        case actionTypes.SET_QUIZ:
            return {
                ...state,
                currentQuiz: action.payload.quiz
            };
        case actionTypes.CHANGE_QUIZ_STATUS:
            const newQuiz: any = {
                ...state.currentQuiz,
                status: action.payload.status
            };
            return {
                ...state,
                currentQuiz: newQuiz
            };
        default:
            return state;
    }
}

export const selectQuizList: any = createSelector(
    [(state) => state.quizState.modoQuizList],
    (modoQuizList) => modoQuizList
);

export const selectCurrentQuiz: any = createSelector(
    [(state) => state.quizState.currentQuiz],
    (currentQuiz) => currentQuiz
);
