// @flow

type ConditionalWrapperProps = {
    condition: boolean,
    wrapper: (children: React$Node) => React$Node,
    children: React$Node
};

const ConditionalWrapper = ({
    condition,
    wrapper,
    children
}: ConditionalWrapperProps): React$Node =>
    condition ? wrapper(children) : children;

export default ConditionalWrapper;
