// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import useMergeProps from '../hooks/useMergeProps';
import type { QuizListPopupProps } from '../components/input_bar_components/QuizListPopup.jsx';
import QuizListPopup from '../components/input_bar_components/QuizListPopup.jsx';
import selectQuizListPopupProps from '../redux/selectors/selectQuizListPopupProps';

type QuizListPopupOwnProps = {
    closePopup: () => void,
    execCommand: (cmd: string, params: string) => void,
    changePopup: () => void
};

const QuizListPopupContainer = (
    ownProps: QuizListPopupOwnProps
): React$Element<(_0: QuizListPopupProps) => React$Element<any>> => {
    const selectedProps = useSelector(selectQuizListPopupProps);

    const enhancedProps = useMergeProps({ ownProps, selectedProps });

    return <QuizListPopup {...enhancedProps} />;
};

export default QuizListPopupContainer;
