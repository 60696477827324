// @flow

import type { MemberInfo } from '../types/room';

import { UserRoles, UserStatus } from '../types/user';
import { __ } from './translator';

export const hslToRgb = (hue: number, sat: number, lit: number): string => {
    const h = hue;
    let s = sat / 100;
    let l = lit / 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    let r = 0;
    let g = 0;
    let b = 0;

    if (h >= 0 && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (h >= 300 && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return 'rgb(' + r + ',' + g + ',' + b + ')';
};

export const rgbToHex = (rgbString: string): string => {
    let sep = rgbString.indexOf(',') > -1 ? ',' : ' ';
    const rgb = rgbString.substring(4).split(')')[0].split(sep);

    let r = (+rgb[0]).toString(16),
        g = (+rgb[1]).toString(16),
        b = (+rgb[2]).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
};

export const getNicknameColor = (nickname: string): string => {
    const hslLimits = {};
    hslLimits.hue = [15, 350];
    hslLimits.sat = [50, 100];
    hslLimits.lit = [40, 65];

    const range = (hash, min, max) => {
        const diff = max - min;
        const x = ((hash % diff) + diff) % diff;
        return x + min;
    };

    let hash = 0;
    if (!nickname) return '';
    for (let i = 0; i < nickname.length; i++) {
        hash = nickname.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    const h = range(hash, hslLimits.hue[0], hslLimits.hue[1]);
    const s = range(hash, hslLimits.sat[0], hslLimits.sat[1]);
    const l = range(hash, hslLimits.lit[0], hslLimits.lit[1]);

    return rgbToHex(hslToRgb(h, s, l));
};

export const getRandomInt = (max: number): number => {
    return Math.floor(Math.random() * max);
};

export const tsToTime = (ts: number): string => {
    let h = new Date(ts).getHours();
    let m = new Date(ts).getMinutes();

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;

    return `${h}:${m}`;
};

const isDayDiff = (
    smallerTs: number,
    greaterTs: number,
    nbDayDiff: number
): boolean => {
    const firstDay = Math.floor(smallerTs / 3600000 / 24);
    const secondDay = Math.floor(greaterTs / 3600000 / 24);
    return secondDay - firstDay === nbDayDiff;
};

export const isSameDay = (firstTs: number, secondTs: number): boolean => {
    return isDayDiff(firstTs, secondTs, 0);
};

export const sortMembersList = (
    memberA: MemberInfo,
    memberB: MemberInfo
): number => {
    if (memberA.role === UserRoles.LEADER) return -1;
    if (memberB.role === UserRoles.LEADER) return 1;
    if (memberA.status === memberB.status) {
        return memberA.nickname.localeCompare(memberB.nickname);
    }
    if (memberA.status === UserStatus.CONNECTED) {
        return -1;
    }
    return 1;
};

const Formatter: any = {};

Formatter.language = 'fr_FR';

Formatter.tsToStringTime = (ts: number): string => {
    let seconds = Math.floor(ts / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    let text = hours ? `${hours}h ` : '';
    text += minutes ? `${minutes}m ` : '';
    text += `${seconds}s`;
    return text;
};

Formatter.tsToDate = (ts: number): string => {
    if (isSameDay(Date.now(), ts)) {
        return __('Today');
    }
    if (isDayDiff(ts, Date.now(), 1)) {
        return __('Yesterday');
    }
    const date = new Date(ts);
    const day = date.getDate();
    const monthName = date.toLocaleString(Formatter.language.replace('_', '-'), {
        month: 'long'
    });
    return `${day} ${monthName}`;
};

Formatter.setLanguage = (lang: string) => {
    Formatter.language = lang;
};

export default Formatter;
