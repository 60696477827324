// @flow

import type { Message } from '../../types/message';
import { MessageTypes } from '../../types/message';

import React from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';
import ButtonCmdAction from '../common/ButtonCmdAction';
import { BanIcon, TimeoutIcon, DeleteIcon, WinamaxLogo } from 'assets/images';
import { tsToTime } from '../../utils/formatter';

const MessageComponentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1px 10px;
    height: max-content;
    flex-shrink: 0;
    align-items: center;
`;

const ModeratorBtnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    align-items: center;
`;

const MessageWrapper = styled.div`
    display: flex;
    align-items: center;
    height: max-content;
`;

const MessageWrapperBis = styled.div`
    text-align: left;
    font-size: ${({ theme }) => theme.font.message.size};
    display: inline;
    height: max-content;
`;

const NicknameWrapper = styled.div`
    display: inline-block;
`;

const Nickname = styled.span`
    font-weight: ${({ theme, $isModerator }) =>
        $isModerator
            ? theme.font.message.modoNicknameWeight
            : theme.font.message.nicknameWeight};
    color: ${({ theme, $isModerator, $nickname }) =>
        $isModerator
            ? theme.color.modoNicknameTxt
            : theme.color.nicknamesTxt[$nickname]};
    padding-right: 5px;
`;

const MessageText = styled.span`
    color: ${({ theme }) => theme.color.mainText};
    overflow-wrap: anywhere;
`;

const MessageStyledButton = styled(ButtonCmdAction)`
    height: 16px;
    margin: 0 5px 0 5px;
`;

const NicknameImgWrapper = styled.span``;

const ModoNicknameImgWrapper = styled.div`
    display: inline;
`;

const ModoNicknameImg = styled(WinamaxLogo)`
    height: 18px;
    margin-bottom: 0.15rem;
    vertical-align: middle;
`;

const MsgTimeText = styled.span`
    color: ${({ theme }) => theme.color.mainText};
    font-size: ${({ theme }) => theme.font.msgTime.size};
    font-weight: ${({ theme }) => theme.font.msgTime.weight};
    margin-left: auto;
`;

export type MessageComponentProps = {
    execCommand: (cmd: string, params: string) => void,
    setPreparedInput: (value: string) => void,
    message: Message,
    isModerator: boolean,
    isDated: boolean
};

const MessageComponent = ({
    message,
    execCommand,
    setPreparedInput,
    isModerator,
    isDated
}: MessageComponentProps): React$Element<any> => {
    const { id, nickname, text, timestamp } = message;
    const msgUserIsModo = message.type === MessageTypes.MODERATOR;
    const execBanCallback = useCallback(
        () => setPreparedInput(`/ban ${nickname} | `),
        [nickname, setPreparedInput]
    );
    const execTimeoutCallback = useCallback(
        () => setPreparedInput(`/to ${nickname} | `),
        [nickname, setPreparedInput]
    );
    const execDeleteMsgCallback = useCallback(
        () => execCommand('/delete_msg', id),
        [id, execCommand]
    );

    const getModeratorBtn = () => (
        <ModeratorBtnWrapper>
            <MessageStyledButton
                commandFunc={execBanCallback}
                Icon={BanIcon}
                alt="BanIcon"
            />
            <MessageStyledButton
                commandFunc={execTimeoutCallback}
                Icon={TimeoutIcon}
                alt="TimeoutIcon"
            />
            <MessageStyledButton
                commandFunc={execDeleteMsgCallback}
                Icon={DeleteIcon}
                alt="DeleteIcon"
            />
        </ModeratorBtnWrapper>
    );

    return (
        <MessageComponentWrapper>
            {isModerator ? getModeratorBtn() : null}
            <MessageWrapper>
                <MessageWrapperBis>
                    <NicknameWrapper>
                        {msgUserIsModo ? (
                            <NicknameImgWrapper>
                                <ModoNicknameImgWrapper>
                                    <ModoNicknameImg alt="WinamaxLogo" />
                                </ModoNicknameImgWrapper>
                            </NicknameImgWrapper>
                        ) : null}
                        <Nickname $isModerator={msgUserIsModo} $nickname={nickname}>
                            {nickname}
                        </Nickname>
                    </NicknameWrapper>
                    <MessageText>{text}</MessageText>
                </MessageWrapperBis>
            </MessageWrapper>
            {isDated ? <MsgTimeText>{tsToTime(timestamp)}</MsgTimeText> : null}
        </MessageComponentWrapper>
    );
};

export default MessageComponent;
