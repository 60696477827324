// @flow

import * as actionTypes from '../../types/actions';
import type { RoomState } from '../../types/states';
import type { ActionType } from '../../types/actions';
import { createSelector } from 'reselect';
import { addUserNicknameColor } from '../../style/themeGenerator';
import config from '../../config/app.config';
import { RoomTypes } from '../../types/room';
import { OriginAppNames } from '../../types/app';
import type { MemberInfo } from '../../types/room';
import { sortMembersList } from '../../utils/formatter';

export const initialRoomState: RoomState = {
    roomName: '',
    toggledFeatures: {
        topWidgets: {
            quiz: false,
            listMembers: false,
            mainMessage: { img: false, changeText: false }
        },
        rightWidgets: {
            listMembers: false
        },
        msgDating: false,
        roomStatus: false,
        slowMode: false
    },
    messages: [],
    mainMessage: '',
    feedUsers: [],
    slowModeTime: 0,
    status: 'CLOSED',
    type: 'PUBLIC',
    members: []
    // nbUser: 0
};

export function roomReducer(
    state: RoomState = initialRoomState,
    action: ActionType
): RoomState {
    switch (action.type) {
        case actionTypes.CONFIGURE_APP:
            const { roomName, originApp } = action.payload;
            return {
                ...state,
                roomName,
                toggledFeatures: {
                    ...state.toggledFeatures,
                    topWidgets: {
                        ...state.toggledFeatures.topWidgets,
                        listMembers: originApp === OriginAppNames.KING5,
                        mainMessage: {
                            ...state.toggledFeatures.topWidgets.mainMessage,
                            img: originApp === OriginAppNames.KING5
                        }
                    },
                    rightWidgets: { listMembers: originApp === OriginAppNames.GNG },
                    msgDating:
                        originApp === OriginAppNames.KING5 ||
                        originApp === OriginAppNames.GNG
                },
                mainMessage: originApp === OriginAppNames.GNG ? 'Chat GANG' : ''
            };
        case actionTypes.RECEIVE_JOIN_INFO:
            const {
                messagesList,
                mainMessage,
                slowModeTime,
                status,
                type,
                members,
                features
            } = action.payload;
            members.sort(sortMembersList);
            const feedUsers = new Set(
                messagesList.map((msg) => {
                    addUserNicknameColor(msg.nickname);
                    return msg.nickname;
                })
            );
            return {
                ...state,
                messages: messagesList,
                toggledFeatures: {
                    ...state.toggledFeatures,
                    topWidgets: {
                        ...state.toggledFeatures.topWidgets,
                        quiz: features.quiz,
                        mainMessage: {
                            ...state.toggledFeatures.topWidgets.mainMessage,
                            changeText: features.mainMessage
                        }
                    },
                    roomStatus: type === RoomTypes.PUBLIC,
                    slowMode: features.slowMode
                },
                mainMessage,
                feedUsers: [...feedUsers],
                slowModeTime,
                status,
                type,
                members
            };
        case actionTypes.RECEIVE_MESSAGE:
            const message = action.payload.message;
            let newFeedUsers = state.feedUsers;
            if (!newFeedUsers.includes(message.nickname)) {
                addUserNicknameColor(message.nickname);
                newFeedUsers = [message.nickname, ...newFeedUsers];
            }
            const msgList =
                state.type === RoomTypes.PUBLIC
                    ? [message, ...state.messages.slice(0, config.msgLimit - 1)]
                    : [message, ...state.messages];
            return {
                ...state,
                messages: msgList,
                feedUsers: newFeedUsers
            };
        case actionTypes.REMOVE_MESSAGE:
            const newList = state.messages.filter(
                (msg) => msg.id !== action.payload.msgId
            );
            return {
                ...state,
                messages: newList
            };
        case actionTypes.REMOVE_USER_MSG:
            const newMsgList = state.messages.filter(
                (msg) => msg.nickname !== action.payload.nickname
            );
            return {
                ...state,
                messages: newMsgList
            };
        case actionTypes.SET_MAIN_MESSAGE:
            return {
                ...state,
                mainMessage: action.payload.msg
            };
        case actionTypes.SET_SLOW_MODE:
            return {
                ...state,
                slowModeTime: action.payload.time
            };
        case actionTypes.SET_STATUS:
            return {
                ...state,
                status: action.payload.status
            };
        case actionTypes.SET_TYPE:
            return {
                ...state,
                type: action.payload.type
            };
        case actionTypes.CHANGE_MEMBER_INFO:
            const { nickname, role, status: userStatus } = action.payload;
            const newMembers = state.members.map((info) => {
                if (info.nickname === nickname) {
                    const newInfo: MemberInfo = {
                        ...info,
                        ...(role && { role }),
                        ...(userStatus && { status: userStatus })
                    };
                    return newInfo;
                }
                return info;
            });
            newMembers.sort(sortMembersList);
            return {
                ...state,
                members: newMembers
            };
        // case actionTypes.SET_NB_USER:
        //     return {
        //         ...state,
        //         nbUser: action.payload.nbUser
        //     };

        default:
            return state;
    }
}

export const selectMainMessage: any = createSelector(
    [(state) => state.roomState.mainMessage],
    (mainMessage) => mainMessage
);

export const selectMessages: any = createSelector(
    [(state) => state.roomState.messages],
    (messages) => messages
);

export const selectSlowModeTime: any = createSelector(
    [(state) => state.roomState.slowModeTime],
    (slowModeTime) => slowModeTime
);

export const selectFeedUsers: any = createSelector(
    [(state) => state.roomState.feedUsers],
    (feedUsers) => feedUsers
);

export const selectStatus: any = createSelector(
    [(state) => state.roomState.status],
    (status) => status
);

export const selectType: any = createSelector(
    [(state) => state.roomState.type],
    (type) => type
);

export const selectMembers: any = createSelector(
    [(state) => state.roomState.members],
    (members) => members
);

export const selectToggledFeatures: any = createSelector(
    [(state) => state.roomState.toggledFeatures],
    (toggledFeatures) => toggledFeatures
);
