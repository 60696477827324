// @flow

import * as actionTypes from '../../types/actions';
import type { UserState } from '../../types/states';
import type { ActionType } from '../../types/actions';
import { UserRoles } from '../../types/user';
import { createSelector } from 'reselect';

export const initialUserState: UserState = {
    userRole: UserRoles.READER
};

export function userReducer(
    state: UserState = initialUserState,
    action: ActionType
): UserState {
    switch (action.type) {
        case actionTypes.RECEIVE_JOIN_INFO:
            return {
                ...state,
                userRole: action.payload.userRole
            };
        case actionTypes.CHANGE_USER_ROLE:
            return {
                ...state,
                userRole: action.payload.userRole
            };
        default:
            return state;
    }
}

export const selectUserRole: any = createSelector(
    [(state) => state.userState.userRole],
    (userRole) => userRole
);

export const selectJwt: any = createSelector(
    [(state) => state.userState.jwt],
    (jwt) => jwt
);
