// @flow

import { selectFeedUsers } from '../reducers/roomReducer';

import { createSelector } from 'reselect';

const selectInputTextAreaProps: any = createSelector(
    [selectFeedUsers],
    (feedUsers) => ({
        feedUsers
    })
);

export default selectInputTextAreaProps;
