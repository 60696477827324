export { ReactComponent as Users } from './user.svg';
export { ReactComponent as Emoji } from './emoji.svg';
export { ReactComponent as AddIcon } from './add.svg';
export { ReactComponent as ArrowDown } from './arrowDown.svg';
export { ReactComponent as BackIcon } from './back.svg';
export { ReactComponent as BanIcon } from './banIcon.svg';
export { ReactComponent as Check } from './checkIcon.svg';
export { ReactComponent as CreateQuizIcon } from './createQuizIcon.svg';
export { ReactComponent as Cross } from './crossIcon.svg';
export { ReactComponent as DeleteIcon } from './delete.svg';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as QuizListIcon } from './quizListIcon.svg';
export { ReactComponent as TimeoutIcon } from './timeout.svg';
export { ReactComponent as WinamaxLogo } from './winamaxLogo.svg';
export { ReactComponent as WinnerIcon } from './winner.svg';
import { ReactComponent as King5Avatar1 } from './king5/avatar_1.svg';
import { ReactComponent as King5Avatar2 } from './king5/avatar_2.svg';
import { ReactComponent as King5Avatar3 } from './king5/avatar_3.svg';
import { ReactComponent as King5Avatar4 } from './king5/avatar_4.svg';
import { ReactComponent as King5Avatar5 } from './king5/avatar_5.svg';
import { ReactComponent as King5Logo } from './king5/logoKing5.svg';

export const MembersAvatar = {
    king5: [King5Avatar1, King5Avatar2, King5Avatar3, King5Avatar4, King5Avatar5]
};

export const MainMsgLogo = {
    king5: King5Logo
};
