// @flow

// Methods
import Http, { HTTP_DATA_JSON } from './http';

/**
 * Constants / Variables
 */

const DELAY_MIN: number = 60;
const DELAY_DEFAULT: number = 8 * 60;

/** Le token de l'authV2 */
let jwtToken = null;

let getToken = null;

/** La timestamp de l'expiration du token de l'authV2 en secondes*/
let jwtTimeExpiration = null;

/** le timestamp calculé en local avec jwtTimeExpirationDelay */
let jwtTimeExpirationLocal = null;

/** Le temps en seconde de durée de vie du token renvoyer par l'api authorize */
let jwtTimeExpirationDelay = null;

/** ID du timer de refresh */
let refreshTokenTmo = null;

/** Authenticate server function */
let setAuthenticate = null;

/** Web endpoint for refresh */
let webEndpoint = null;

let refreshTokenId = 1;
let retryRefreshCount = 0;

/**
 * Methods
 */

// Donne le timestamp courant, en secondes avec un précision après la virgule (millisecondes ou microsecondes).
const getTimestampHighRes = function (): number {
    let ms = Date.now();

    try {
        ms = performance.timing.navigationStart + performance.now();
    } catch (e) {}

    return ms / 1000;
};

const setToken = function (
    token: string,
    expirationTime?: number,
    expirationDelay?: number
): void {
    const now = Math.floor(Date.now() / 1000);
    let delay = DELAY_DEFAULT;

    jwtToken = token;

    if (expirationTime && expirationDelay) {
        const delayRandom = DELAY_MIN + 30 + Math.round(Math.random() * 120);

        jwtTimeExpiration = expirationTime;
        jwtTimeExpirationLocal = expirationDelay + now;
        jwtTimeExpirationDelay = expirationDelay;

        delay = jwtTimeExpirationDelay - delayRandom;
    } else {
        // Le token provient du site web (cookies), on fake les infos avant de lancer le refresh rapidement
        delay = DELAY_MIN + 30;
        jwtTimeExpirationDelay = 2 * delay;
        jwtTimeExpiration = now + jwtTimeExpirationDelay;
        jwtTimeExpirationLocal = jwtTimeExpiration;
    }

    clearTimeout(refreshTokenTmo);

    if (delay >= DELAY_MIN) {
        refreshTokenTmo = setTimeout(Token.refreshToken, delay * 1000);
    } else {
    }
};

function clearToken(clearPassword: ?boolean) {
    if (refreshTokenTmo) {
        clearTimeout(refreshTokenTmo);
    }

    refreshTokenTmo = null;
    jwtToken = null;
    jwtTimeExpiration = null;
    jwtTimeExpirationLocal = null;
    jwtTimeExpirationDelay = null;
}

function onRefreshToken(payload: ?Object) {
    if (
        payload &&
        payload.result &&
        payload.result.token &&
        payload.result.expiration_time
    ) {
        if (!payload.result.expiration_delay) {
            payload.result.expiration_delay = DELAY_DEFAULT;
        }

        // On set le token et on repasse le nombre de retry a 0
        setToken(
            payload.result.token,
            payload.result.expiration_time,
            payload.result.expiration_delay
        );

        if (setAuthenticate) {
            setAuthenticate(payload.result.token);
        }

        retryRefreshCount = 0;
    } else {
        onRefreshError(payload);
    }
}

function onRefreshError(payload: ?Object) {
    const now = Math.floor(Date.now() / 1000);
    const MAX_RETRY_REFRESH = 3;
    const RETRY_REFRESH_DELAY = 2500;

    // Si le token à expiré, on ne redemande pas de refresh
    if (
        !jwtTimeExpirationLocal ||
        jwtTimeExpirationLocal - DELAY_MIN < now ||
        retryRefreshCount >= MAX_RETRY_REFRESH
    ) {
        clearToken();
    } else {
        retryRefreshCount++;
        refreshTokenTmo = setTimeout(Token.refreshToken, RETRY_REFRESH_DELAY);
    }
}

/**
 * Component
 */
let Token: Object = {};

Token.getJwtFromCookies = () => {
    const loginDataRegExp = new RegExp('_GTM=([^;]+)');
    const isJwtInCookie = !!document.cookie.match(loginDataRegExp);

    if (isJwtInCookie) {
        const name = '_GTM=';
        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; ++i) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
    }
    return '';
};

// Refresh web token
Token.refreshToken = function () {
    if (webEndpoint) {
        const service = 'core/authentication/token/refresh';
        const url = webEndpoint + '/' + service;
        const infos = {
            method: 'RefreshToken',
            params: { token: jwtToken },
            ts: getTimestampHighRes(),
            ns: service,
            id: 'chat-' + __BUILD_TARGET__ + '-' + refreshTokenId++
        };

        Http.post(url, HTTP_DATA_JSON, infos, 10000 || 5000)
            .then((payload) => {
                let infos = payload;

                if (infos && typeof infos === 'string') {
                    infos = JSON.parse(infos);
                }

                //Sur l'auth on rejete si la payload ne contient pas de champs result
                if (!infos || !infos.result) {
                    return Promise.reject(infos);
                }

                return Promise.resolve(infos);
            })
            .then(onRefreshToken)
            .catch(onRefreshError);
    } else {
        onRefreshError();
    }
};

Token.getWebToken = function (setAuthenticateFunc: any) {
    setAuthenticate = setAuthenticateFunc;

    if (!jwtToken) {
        jwtToken = Token.getJwtFromCookies();

        if (jwtToken) {
            // Refresh token after 1 min (Arbitrary value)
            refreshTokenTmo = setTimeout(Token.refreshToken, 60000);
        }
    }
    return jwtToken;
};

Token.setGetTokenFunction = (getTokenFunc: any) => {
    getToken = getTokenFunc;
};

Token.getTokenFunc = () => getToken;

Token.setRefreshEndpoint = (endpoint: string) => {
    webEndpoint = endpoint;
};

export default Token;
