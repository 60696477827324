// @flow

import type { ActionType } from '../../types/actions';
import type { InputAlert, Message } from '../../types/message';

import type { Quiz, QuizStatus } from '../../types/quiz';
import type { UserRoleType, UserStatusType } from '../../types/user';

import type {
    FeaturesFromApi,
    MemberInfo,
    RoomStatusType,
    RoomType
} from '../../types/room';

import * as types from '../../types/actions';
import type { OriginApps } from '../../types/app';

export const startConnecting = (): ActionType => ({
    type: types.START_CONNECTING,
    payload: {}
});

export const connectionEstablished = (): ActionType => ({
    type: types.CONNECTION_ESTABLISHED,
    payload: {}
});

export const connectionFailed = (): ActionType => ({
    type: types.CONNECTION_FAILED,
    payload: {}
});

export const configureApp = (
    roomName: string,
    env: string,
    operator: string,
    originApp: OriginApps
): ActionType => ({
    type: types.CONFIGURE_APP,
    payload: { roomName, env, operator, originApp }
});

export const receiveJoinInfo = (
    messagesList: Array<Message>,
    userRole: UserRoleType,
    mainMessage: string,
    quiz: ?Quiz,
    slowModeTime: number,
    status: RoomStatusType,
    type: RoomType,
    members: Array<MemberInfo>,
    features: FeaturesFromApi
): ActionType => ({
    type: types.RECEIVE_JOIN_INFO,
    payload: {
        messagesList,
        userRole,
        mainMessage,
        quiz,
        slowModeTime,
        status,
        type,
        members,
        features
    }
});

export const receiveMessage = (message: Message): ActionType => ({
    type: types.RECEIVE_MESSAGE,
    payload: {
        message
    }
});

export const removeMessage = (msgId: string): ActionType => ({
    type: types.REMOVE_MESSAGE,
    payload: {
        msgId
    }
});

export const sendMessage = (message: string): ActionType => ({
    type: types.SEND_MESSAGE,
    payload: {
        message
    }
});

export const execCommand = (command: string, params: string): ActionType => ({
    type: types.EXEC_COMMAND,
    payload: {
        command,
        params
    }
});

export const raiseInputAlert = (inputAlert: ?InputAlert): ActionType => ({
    type: types.RAISE_INPUT_ALERT,
    payload: {
        inputAlert
    }
});

export const setMainMessage = (msg: string): ActionType => ({
    type: types.SET_MAIN_MESSAGE,
    payload: {
        msg
    }
});

export const changeUserRole = (userRole: UserRoleType): ActionType => ({
    type: types.CHANGE_USER_ROLE,
    payload: {
        userRole
    }
});

export const storeQuizList = (quizList: Array<Quiz>): ActionType => ({
    type: types.STORE_QUIZ_LIST,
    payload: {
        quizList
    }
});

export const setQuiz = (quiz: ?Quiz): ActionType => ({
    type: types.SET_QUIZ,
    payload: {
        quiz
    }
});

export const setStatus = (status: RoomStatusType): ActionType => ({
    type: types.SET_STATUS,
    payload: {
        status
    }
});

export const changeQuizStatus = (status: QuizStatus): ActionType => ({
    type: types.CHANGE_QUIZ_STATUS,
    payload: {
        status
    }
});

export const setSlowMode = (time: number): ActionType => ({
    type: types.SET_SLOW_MODE,
    payload: {
        time
    }
});

export const removeUserMessage = (nickname: string): ActionType => ({
    type: types.REMOVE_USER_MSG,
    payload: {
        nickname
    }
});

export const setPreparedInput = (value: string): ActionType => ({
    type: types.SET_PREPARED_INPUT,
    payload: {
        value
    }
});

export const setType = (type: RoomType): ActionType => ({
    type: types.SET_TYPE,
    payload: {
        type
    }
});

export const changeMemberInfo = (
    nickname: string,
    role: ?UserRoleType,
    status: ?UserStatusType
): ActionType => ({
    type: types.CHANGE_MEMBER_INFO,
    payload: {
        nickname,
        role,
        status
    }
});

// export const setNbUser = (nb: number): ActionType => ({
//     type: types.SET_NB_USER,
//     payload: {
//         nbUser: nb
//     }
// });
