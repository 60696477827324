// @flow

import React from 'react';

import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import { execCommand } from '../redux/actions/chatActions';
import MainMessageComponent from '../components/feed_components/MainMessageComponent';
import type { MainMessageComponentProps } from '../components/feed_components/MainMessageComponent';
import { useSelector } from 'react-redux';
import selectMainMessageComponentProps from '../redux/selectors/selectMainMessageComponentProps';

type MainMessageComponentOwnProps = {
    mainMessage: string,
    isRoomClosed: boolean
};

const MainMessageComponentContainer = (
    ownProps: MainMessageComponentOwnProps
): React$Element<(_0: MainMessageComponentProps) => React$Element<any>> => {
    const actionsProps = useBinActionsCreator({
        execCommand
    });

    const selectedProps = useSelector(selectMainMessageComponentProps);

    const enhancedProps = useMergeProps({ actionsProps, ownProps, selectedProps });

    return <MainMessageComponent {...enhancedProps} />;
};

export default MainMessageComponentContainer;
