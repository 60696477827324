export const size = {
    bettingFrame: '350px',
    mobileS: '370px',
    mobileM: '500px'
};

export const frame = {
    betting: `(max-width: ${size.bettingFrame})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`
};
