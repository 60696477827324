// @flow

import type { Quiz } from '../../types/quiz';

import { quizStatus } from '../../types/quiz';
import styled from 'styled-components';

import React from 'react';
import { useEffect, useState } from 'react';
import { frame } from '../../style/screenSize';

import { __ } from '../../utils/translator';
import { StyledButton } from '../common/sharedStyledComponent';

const QuizWrapper = styled.div`
    background-color: ${({ theme }) => theme.color.secondBackground};
    align-items: center;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
`;

const QuizMessage = styled.div`
    font-size: ${({ theme }) => theme.font.quizQuestion.size};
    font-weight: ${({ theme }) => theme.font.quizQuestion.weight};
    text-align: center;
    color: ${({ theme }) => theme.color.quizTitle};
    margin: 5px 0 5px 0;
`;

const AnswersWrapper = styled.div`
    margin: 0 15px 10px 15px;
    padding: 10px 10px 10px 10px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.thirdLayerBg};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const AnswerButton = styled(StyledButton)`
    width: 150px;
    height: max-content;
    margin: 5px 5px 5px 5px;
    padding: 5px 5px;
    border-radius: 6px;
    font-size: ${({ theme }) => theme.font.quizAnswer.size};
    background-color: ${({ theme }) => theme.color.formButton};
    cursor: pointer;

    @media ${frame.mobileS} {
        max-width: 135px;
    }
`;

export type QuizComponentProps = {
    quiz: Quiz,
    execCommand: (cmd: string, params: string) => void,
    quizDisabled: boolean
};

const QuizComponent = ({
    quiz,
    execCommand,
    quizDisabled
}: QuizComponentProps): React$Element<any> => {
    const [showQuiz, setShowQuiz] = useState(quiz.status !== quizStatus.ANSWERED);

    useEffect(() => {
        if (showQuiz && quiz.status === quizStatus.ANSWERED) {
            const id = setTimeout(() => {
                setShowQuiz(false);
            }, 10000);
            return () => {
                clearTimeout(id);
            };
        }
    }, [quiz.status, showQuiz]);

    const handleOnClick = (e, responseIndex) => {
        execCommand('/quiz_answer', responseIndex.toString());
    };

    return (
        <React.Fragment>
            {showQuiz ? (
                <QuizWrapper>
                    {quiz.status === quizStatus.OPEN ? (
                        <div>
                            <QuizMessage> {quiz.question} </QuizMessage>
                            <AnswersWrapper>
                                {quiz.responses.map((response, index) => (
                                    <AnswerButton
                                        onClick={(e) => handleOnClick(e, index)}
                                        disabled={quizDisabled}
                                        key={`${response}-${index}`}>
                                        {response}
                                    </AnswerButton>
                                ))}
                            </AnswersWrapper>
                        </div>
                    ) : quiz.status === quizStatus.ANSWERED ? (
                        <div>
                            <QuizMessage>{__('Quiz_answer_registered')}</QuizMessage>
                        </div>
                    ) : null}
                </QuizWrapper>
            ) : null}
        </React.Fragment>
    );
};

export default QuizComponent;
