// @flow

import React from 'react';

import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import { raiseInputAlert, execCommand } from '../redux/actions/chatActions';
import type { Quiz } from '../types/quiz';
import QuizListElement from '../components/input_bar_components/QuizListElement.jsx';
import type { QuizListElementProps } from '../components/input_bar_components/QuizListElement.jsx';

type QuizListElementOwnProps = {
    quiz: Quiz,
    index?: number,
    changePopup?: (quizToEdit: ?Quiz) => void
};

const QuizListElementContainer = (
    ownProps: QuizListElementOwnProps
): React$Element<(_0: QuizListElementProps) => React$Element<any>> => {
    const actionsProps = useBinActionsCreator({
        raiseInputAlert,
        execCommand
    });

    const enhancedProps = useMergeProps({ actionsProps, ownProps });

    return <QuizListElement {...enhancedProps} />;
};

export default QuizListElementContainer;
