// @flow

import { __ } from './translator';

export const getDrawMessage = (
    nicknames: Array<string>,
    correctAnswer: string
): string => {
    const winners = nicknames.join(', ');
    // prettier-ignore
    return `${__('Congrats %1$s ! Right_answer_was %2$s', [ winners, correctAnswer ])}`;
};

export const getSlowModeMessage = (timeLeft: number): string =>
    `${'Slow_mode_active'} ${timeLeft} sec`;
