// @flow

import React from 'react';

import { ChatApp } from './components/ChatApp';
import type { ChatAppProps } from './components/ChatApp';
import { render } from 'react-dom';

if (__BUILD_TARGET__ === 'web') {
    const webProps: ChatAppProps = {
        roomName: window.config.ROOM_NAME,
        applicationName: window.config.APP_NAME,
        language: window.config.LANG,
        operator: 'wam',
        env: __BUILD_MODE__ === 'development' ? 'dev' : 'prod',
        refreshTokenEndpoint: '',
        navigate: (action: string) => console.error(action),
        chatWrapperStyle: undefined
    };
    const rootElement = document.getElementById('root');
    if (rootElement) render(<ChatApp {...webProps} />, rootElement);
}

export default ChatApp;
