// @flow

export const AlertTypes = Object.freeze({
    INFO: 'INFO',
    ERROR: 'ERROR'
});

export type AlertType = $Values<typeof AlertTypes>;

export type InputAlert = {
    type: AlertType,
    text: string
};

export const createInputAlert = (type: AlertType, text: string): InputAlert => ({
    type,
    text
});

export const MessageTypes = Object.freeze({
    DEFAULT: 'DEFAULT',
    MODERATOR: 'MODERATOR'
});

export type MessageType = $Values<typeof MessageTypes>;

export type Message = {
    id: string,
    nickname: string,
    text: string,
    timestamp: number,
    type: MessageType
};
