// @flow

import {
    selectIsConnected,
    selectIsEstablishingConnection
} from '../reducers/chatReducer';

import { selectCurrentQuiz } from '../reducers/quizReducer';

import {
    selectToggledFeatures,
    selectMainMessage,
    selectStatus,
    selectType
} from '../reducers/roomReducer';

import { selectUserRole } from '../reducers/userReducer';

import { RoomStatus, RoomTypes } from '../../types/room';

import { createSelector } from 'reselect';
import { UserRoles } from '../../types/user';

const selectPropsFromChatApp: any = createSelector(
    [selectIsConnected, selectIsEstablishingConnection],
    (isConnected, isEstablishingConnection) => ({
        isConnected,
        isEstablishingConnection
    })
);

const selectChatRootProps: any = createSelector(
    [
        selectPropsFromChatApp,
        selectCurrentQuiz,
        selectMainMessage,
        selectStatus,
        selectUserRole,
        selectType,
        selectToggledFeatures
    ],
    (
        chatAppProps,
        currentQuiz,
        mainMessage,
        status,
        userRole,
        type,
        toggledFeatures
    ) => ({
        ...chatAppProps,
        quiz: currentQuiz,
        mainMessage,
        toggledFeatures,
        isRoomClosed:
            status === RoomStatus.CLOSED && userRole !== UserRoles.MODERATOR,
        isUserAllowed:
            type === RoomTypes.PUBLIC ||
            (type === RoomTypes.PRIVATE && userRole !== UserRoles.NOT_ALLOWED)
    })
);

export default selectChatRootProps;
