// @flow

import { createSelector } from 'reselect';
import { UserRoles } from '../../types/user';
import { selectUserRole } from '../reducers/userReducer';
import { selectToggledFeatures } from '../reducers/roomReducer';

const selectMainMessageComponentProps: any = createSelector(
    [selectUserRole, selectToggledFeatures],
    (userRole, toggledFeatures) => ({
        isModerator: userRole === UserRoles.MODERATOR,
        hasImg: toggledFeatures.topWidgets.mainMessage.img
    })
);

export default selectMainMessageComponentProps;
