// @flow

import React from 'react';

import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import { execCommand } from '../redux/actions/chatActions';
import QuizComponent from '../components/feed_components/QuizComponent';
import type { QuizComponentProps } from '../components/feed_components/QuizComponent.jsx';
import type { Quiz } from '../types/quiz';
import { useSelector } from 'react-redux';
import selectQuizComponentProps from '../redux/selectors/selectQuizComponentProps';

type QuizComponentOwnProps = {
    quiz: Quiz
};

const QuizComponentContainer = (
    ownProps: QuizComponentOwnProps
): React$Element<(_0: QuizComponentProps) => React$Element<any>> => {
    const actionsProps = useBinActionsCreator({
        execCommand
    });

    const selectedProps = useSelector(selectQuizComponentProps);

    const enhancedProps = useMergeProps({ actionsProps, ownProps, selectedProps });

    return <QuizComponent {...enhancedProps} />;
};

export default QuizComponentContainer;
