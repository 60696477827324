// @flow

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import InputBarContainer from '../containers/InputBarContainer';
import FeedContainer from '../containers/FeedContainer.jsx';
import type { Quiz } from '../types/quiz';
import QuizComponentContainer from '../containers/QuizComponentContainer.jsx';
import MainMessageComponentContainer from '../containers/MainMessageComponentContainer.jsx';
import { Radio } from 'react-loader-spinner';
import type { FeaturesToggle, RightWidgetsFeat } from '../types/room';
import MembersListContainer from '../containers/MembersListContainer';
import type { OriginApps } from '../types/app';
import { __ } from '../utils/translator';
import ConditionalWrapper from './common/ConditionalWrapper';
import { frame } from '../style/screenSize';
import { Column, Row } from './common/sharedStyledComponent';
import ButtonCmdAction from './common/ButtonCmdAction.jsx';
import { BackIcon } from 'assets/images';

const ChatAppWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.mainBackground};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: ${({ theme }) => theme.font.general.family};
    font-weight: ${({ theme }) => theme.font.general.weight};
`;

const ErrorWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.mainBackground};
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
`;

const AlertMsg = styled.h1`
    text-align: center;
    font: bold;
    color: ${({ theme }) => theme.color.errorColor};
`;

const MembersSeparator = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.color.secondBackground};
`;

const ChatWithRightWidgets = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const FeedWrapper = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media ${frame.mobileM} {
        visibility: ${({ $isRightWidgetsOpen }) =>
            !$isRightWidgetsOpen ? 'visible' : 'hidden'};
        width: ${({ $isRightWidgetsOpen }) =>
            !$isRightWidgetsOpen ? '100%' : '0%'};
        transition: width 0.5s, visibility 0.25s;
    }
`;

const RightWidgetsWrapper = styled(Column)`
    width: 30%;

    @media ${frame.mobileM} {
        width: ${({ $isRightWidgetsOpen }) => ($isRightWidgetsOpen ? '100%' : '0%')};
        transition: width 0.5s;
    }
`;

const RightWidgetsHeader = styled(Row)`
    height: 0;
    width: 0;

    @media ${frame.mobileM} {
        height: auto;
        width: auto;
        justify-content: start;
        align-items: center;

        background-color: ${({ theme }) => theme.color.secondBackground};
    }
`;

const BackButton = styled(ButtonCmdAction)`
    height: 0;
    width: 0;

    @media ${frame.mobileM} {
        margin: 5px 0 5px 5px;
        height: 20px;
        width: auto;
    }
`;

export const ScrollButtonContext: React$Context<any> = React.createContext({
    showScrollButton: false,
    setShowScrollButton: (state: boolean) => false,
    scrollFeedBottom: () => {},
});

type RightWidgetsContextType = {
    hasRightWidgets: boolean,
    isWidgetsOpen: boolean,
    setIsWidgetsOpen: Function,
    widgetsToggled: RightWidgetsFeat,
};

export const RightWidgetsContext: React$Context<RightWidgetsContextType> =
    React.createContext({
        hasRightWidgets: false,
        isWidgetsOpen: false,
        setIsWidgetsOpen: (state: boolean) => false,
        widgetsToggled: {},
    });

export type ChatRootProps = {
    env: string,
    operator: string,
    roomName: string,
    applicationName: OriginApps,
    isConnected: boolean,
    isEstablishingConnection: boolean,
    mainMessage: string,
    quiz: Quiz,
    isRoomClosed: boolean,
    isUserAllowed: boolean,
    toggledFeatures: FeaturesToggle,
    chatWrapperStyle?: Object,
    startConnecting: () => void,
    configureApp: (
        roomName: string,
        env: string,
        operator: string,
        originApp: OriginApps
    ) => void,
};

// Principal component of the chat
export const ChatRoot = ({
    env,
    operator,
    roomName,
    applicationName,
    isConnected,
    isEstablishingConnection,
    mainMessage,
    quiz,
    isRoomClosed,
    isUserAllowed,
    toggledFeatures,
    chatWrapperStyle,
    startConnecting,
    configureApp,
}: ChatRootProps): React$Element<any> => {
    const [showScrollButton, setShowScrollButton] = useState(false);
    const hasRightWidgets = Object.values(toggledFeatures.rightWidgets).some(
        (isFeatToggled) => isFeatToggled
    );
    const [isRightWidgetsOpen, setIsRightWidgetsOpen] = useState(false);
    const feedRef = useRef(null);
    const bottomFeedRef = useRef(null);
    const theme = useTheme();

    const closeRightWidgetsCb = useCallback(
        () => setIsRightWidgetsOpen(false),
        [setIsRightWidgetsOpen]
    );

    useEffect(() => {
        configureApp(roomName, env, operator, applicationName);
        startConnecting();
    }, []);

    const scrollFeedBottom = () => {
        feedRef.current?.scrollTo({
            behavior: 'smooth',
            top: bottomFeedRef.current?.offsetTop,
        });
    };

    const scrollButtonContext = {
        showScrollButton,
        setShowScrollButton,
        scrollFeedBottom,
    };

    const rightWidgetsContext = {
        hasRightWidgets,
        isWidgetsOpen: isRightWidgetsOpen,
        setIsWidgetsOpen: setIsRightWidgetsOpen,
        widgetsToggled: toggledFeatures.rightWidgets,
    };

    const getTopWidgets = () => {
        const toggledTopWidgets = toggledFeatures.topWidgets;
        return (
            <React.Fragment>
                {toggledTopWidgets.quiz && quiz && !isRoomClosed ? (
                    <QuizComponentContainer quiz={quiz} />
                ) : mainMessage || isRoomClosed ? (
                    <RightWidgetsContext.Provider value={rightWidgetsContext}>
                        <MainMessageComponentContainer
                            mainMessage={mainMessage}
                            isRoomClosed={isRoomClosed}
                        />
                    </RightWidgetsContext.Provider>
                ) : null}
                {toggledTopWidgets.listMembers ? (
                    <React.Fragment>
                        <MembersListContainer />
                        <MembersSeparator />
                    </React.Fragment>
                ) : null}
            </React.Fragment>
        );
    };

    const getRightWidgets = () => {
        const toggledRightWidgets = toggledFeatures.rightWidgets;
        return (
            <React.Fragment>
                {toggledRightWidgets.listMembers ? (
                    <MembersListContainer isDisplayVertical={true} />
                ) : null}
            </React.Fragment>
        );
    };

    const getChatApp = () => {
        return (
            <ChatAppWrapper style={chatWrapperStyle}>
                <ConditionalWrapper
                    condition={hasRightWidgets}
                    wrapper={(children) => (
                        <ChatWithRightWidgets>
                            <FeedWrapper $isRightWidgetsOpen={isRightWidgetsOpen}>
                                {children}
                            </FeedWrapper>
                            <RightWidgetsWrapper
                                $isRightWidgetsOpen={isRightWidgetsOpen}
                            >
                                <RightWidgetsHeader>
                                    <BackButton
                                        alt="BackToFeedIcon"
                                        commandFunc={closeRightWidgetsCb}
                                        Icon={BackIcon}
                                    />
                                </RightWidgetsHeader>
                                {getRightWidgets()}
                            </RightWidgetsWrapper>
                        </ChatWithRightWidgets>
                    )}
                >
                    <ScrollButtonContext.Provider value={scrollButtonContext}>
                        {getTopWidgets()}
                        <FeedContainer
                            isRoomClosed={isRoomClosed}
                            bottomFeedRef={bottomFeedRef}
                            feedRef={feedRef}
                        />
                        <InputBarContainer />
                    </ScrollButtonContext.Provider>
                </ConditionalWrapper>
            </ChatAppWrapper>
        );
    };

    return (
        <React.Fragment>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
            />
            {isEstablishingConnection && roomName ? (
                isConnected ? (
                    isUserAllowed ? (
                        getChatApp()
                    ) : (
                        <ErrorWrapper>
                            <AlertMsg>{__('Room_not_allowed')}</AlertMsg>
                        </ErrorWrapper>
                    )
                ) : (
                    <ErrorWrapper>
                        <Radio
                            colors={[
                                theme.color.errorColor,
                                theme.color.errorColor,
                                theme.color.errorColor,
                            ]}
                        />
                    </ErrorWrapper>
                )
            ) : (
                <ErrorWrapper>
                    <AlertMsg>{__('Auth_fail')}</AlertMsg>
                </ErrorWrapper>
            )}
        </React.Fragment>
    );
};
