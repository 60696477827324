// @flow

import { selectMessages, selectToggledFeatures } from '../reducers/roomReducer';

import { createSelector } from 'reselect';

const selectFeedProps: any = createSelector(
    [selectMessages, selectToggledFeatures],
    (messages, toggledFeatures) => ({
        list: messages,
        isMsgDated: toggledFeatures.msgDating
    })
);

export default selectFeedProps;
