// @flow
import React from 'react';
import styled from 'styled-components';
import { Label, LabelText } from './sharedStyledComponent';

const Input = styled.input`
    display: block;
    width: 400px;
    height: 50px;
    border-radius: 6px;
    border: solid 1px ${({ theme }) => theme.color.formInputBorder};
    background-color: transparent;
    color: ${({ theme }) => theme.color.mainText};
    padding-left: 0.7rem;
    font-size: ${({ theme }) => theme.font.formInput.size};
`;

type FormInputProps = {
    label: string,
    value: string,
    disabled?: boolean,
    handleChange: (event: any) => void
};

const FormInput = ({
    label,
    value,
    disabled = false,
    handleChange
}: FormInputProps): React$Element<any> => {
    return (
        <Label>
            <LabelText>{label} :</LabelText>
            <Input disabled={disabled} value={value} onChange={handleChange} />
        </Label>
    );
};

export default FormInput;
