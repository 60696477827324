// @flow

import React from 'react';
import { useSelector } from 'react-redux';

import selectInputBarProps from '../redux/selectors/selectInputBarProps';
import useBinActionsCreator from '../hooks/useBindActionsCreator';
import useMergeProps from '../hooks/useMergeProps';
import {
    sendMessage,
    execCommand,
    raiseInputAlert
} from '../redux/actions/chatActions';
import InputBar from '../components/InputBar';
import type { InputBarProps } from '../components/InputBar.jsx';

const InputBarContainer = (): React$Element<
    (_0: InputBarProps) => React$Element<any>
> => {
    const selectedProps = useSelector(selectInputBarProps);

    const actionsProps = useBinActionsCreator({
        sendMessage,
        execCommand,
        raiseInputAlert
    });

    const enhancedProps = useMergeProps({ selectedProps, actionsProps });

    return <InputBar {...enhancedProps} />;
};

export default InputBarContainer;
