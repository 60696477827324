export default {
  "l10n": {
    "currency": '\u20ac',
    "date": {
      "months": {
        "full": ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
        "abbr": ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]
      },
      "days": {
        "full": ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
        "abbr": ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
      },
      "patterns": {
        "longDate": "dddd, MMMM D, YYYY",
        "dateSlash": "MM/DD/YYYY",
        "dateShort": "dddd, MMMM D",
        "dateShortWithDay": "Do MMMM YYYY",
        "dateMedium": "dddd, MMMM D - h[:]mm a",
        "dateMediumReversed" : "H[:]mm - D MMMM YYYY",
        "dateMediumSlash": "DD[/]MM[/]YYYY H[:]mm a",
        "dateLarge": "D MMM YYYY h[:]mm a",
        "timeShort": "LT",
        "timeMedium": "DD[/]MM LT"
      }
    },
    "number": {
      "patterns": {
        "standard": ["-n", "n"],
        "amount": ["(Sn)", "Sn"]
      },
      "group": {
        "separator": ",",
        "length": 3,
        "abbr": ["", "k", "M", "B"]
      },
      "decimal": {
        "separator": ".",
        "length": 2
      },
      "ordinal": function(number) { var b = number % 10; return number + ((number % 100 / 10) === 1 ? 'th' : (b === 1) ? 'st' : (b === 2) ? 'nd' : (b === 3) ? 'rd' : 'th'); }
    }
  },
  "translations": {
    "messages": {
  "Answer": [
    null,
    "Answer"
  ],
  "Auth_fail": [
    null,
    "Unable to log in, please refresh the page"
  ],
  "Close_room_question": [
    null,
    "Do you really want to close the chat? "
  ],
  "Cmd_not_exists": [
    null,
    "The command does not exist"
  ],
  "Cmd_syntax": [
    null,
    "Incorrect syntax for: "
  ],
  "Congrats %1$s ! Right_answer_was %2$s": [
    null,
    "Congrats %1$s! The correct answer was %2$s"
  ],
  "Connect_to_send_msg": [
    null,
    "Please sign in to your account in order to send messages"
  ],
  "Create": [
    null,
    "Create"
  ],
  "Create_quiz": [
    null,
    "Create quiz"
  ],
  "Definitively": [
    null,
    "Definitively"
  ],
  "Edit": [
    null,
    "Edit"
  ],
  "Edit_quiz": [
    null,
    "Edit quiz"
  ],
  "Feature_not_toggled": [
    null,
    "This feature is not activated for this room"
  ],
  "Flooding": [
    null,
    "Flooding"
  ],
  "For_the_reason": [
    null,
    "For the following reason"
  ],
  "Moderator": [
    null,
    "Moderator"
  ],
  "No": [
    null,
    "No"
  ],
  "No_answer_setted": [
    null,
    "The correct answer has not been set, please edit the quiz"
  ],
  "No_right_answer": [
    null,
    "No right answer"
  ],
  "No_winners": [
    null,
    "No winner(s) at the moment, please try again later."
  ],
  "Not_authorized_banned %1$s Because_of %2$s": [
    null,
    "Unauthorized action, you have been banned %1$s for %2$s"
  ],
  "Optional": [
    null,
    "Optional"
  ],
  "Question": [
    null,
    "Question"
  ],
  "Quiz": [
    null,
    "Quiz"
  ],
  "Quiz_answer_registered": [
    null,
    "You have submitted your answer! Thanks!"
  ],
  "Quiz_answered": [
    null,
    "You have already answered"
  ],
  "Quiz_in_progress": [
    null,
    "A quiz is already in progress"
  ],
  "Required_field": [
    null,
    "Missing mandatory field"
  ],
  "Required_rights": [
    null,
    "You need permission to perform this action"
  ],
  "Room_closed": [
    null,
    "Room is currently closed"
  ],
  "Room_not_allowed": [
    null,
    "You do not have the permissions to join this room"
  ],
  "Slow_mode_changed": [
    null,
    "Slow-mode has been changed"
  ],
  "Today": [
    null,
    "Today"
  ],
  "User_banned": [
    null,
    "User banned"
  ],
  "User_not_banned": [
    null,
    "User is not banned"
  ],
  "User_unbanned": [
    null,
    "User unbanned"
  ],
  "Word_banned": [
    null,
    "Banned word"
  ],
  "Word_unbanned": [
    null,
    "Unbanned word"
  ],
  "Write_banner": [
    null,
    "Write banner text"
  ],
  "Write_message": [
    null,
    "Write message "
  ],
  "Yes": [
    null,
    "Yes"
  ],
  "Yesterday": [
    null,
    "Yesterday"
  ]
}
  }
}
