// @flow

// Modules
const $ = require('./lib/zepto');

type Method = 'GET' | 'POST';

function onRequestOk(
    resolve: AnyFunction,
    url: string,
    type: string,
    payload: ?Object | ?string
) {
    let infos = payload || null;

    if (infos) {
        if (typeof infos === 'string' && type === HTTP_DATA_JSON) {
            infos = JSON.parse(infos);
        }
    }

    resolve(infos);
}

function onRequestError(
    reject: AnyFunction,
    url: string,
    xhr: any,
    errorType: any,
    error: any
) {
    reject();
}

/**
 * Effectue une requête http en utilisant un appel ajax.
 *
 * @param method La méthode à utiliser pour la requête (GET/POST).
 * @param url L'url de la requête.
 * @param data Les données à envoyer.
 * @param timeout La durée du timeout, en millisecondes.
 * @return Une promesse.
 */
function request(
    method: Method,
    url: string,
    type: string,
    data: ?Object,
    timeout: ?number
): Promise<any> {
    return new Promise((resolve, reject) => {
        const isPost = method === 'POST';

        const ajaxOptions = {
            url: url,
            type: method,
            processData: !isPost,
            data: isPost ? JSON.stringify(data) : data,
            dataType: type,
            success: onRequestOk.bind(null, resolve, url, type),
            error: onRequestError.bind(null, reject, url),
            timeout: timeout || TIMEOUT_DEFAULT
        };

        $.ajax(ajaxOptions);
    });
}

/** Le timeout par défaut des requêtes, en millisecondes. */
const TIMEOUT_DEFAULT = 15000;

const Http: Object = {};

Http.get = function (
    url: string,
    type: string,
    data: ?Object,
    timeout: ?number
): Promise<any> {
    return request('GET', url, type, data, timeout);
};

Http.post = function (
    url: string,
    type: string,
    data: ?Object,
    timeout: ?number
): Promise<any> {
    return request('POST', url, type, data, timeout);
};

export default Http;

export const HTTP_DATA_JSON = 'json';
export const HTTP_DATA_TEXT = 'text';
